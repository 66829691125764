import React from 'react';
import {Typography,Button} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { Redirect } from 'react-router';

import ReactModal from 'react-modal';
import { API, graphqlOperation } from 'aws-amplify';
import Auth from '@aws-amplify/auth';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class Entidades extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      redirectAdd: false,
      redirectEdit: false,
      showModal: false,
      accion: String,
      username: String,
      sub: String,
      ProviderEmail: String,
      datos: [],
      open: false, 
      entidadBorrar: String,
      entidadEditar: String
    };
    
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  
  onChangeText(key, value) {
    this.setState({
      [key]: value
    });
  }
  
  async componentDidMount() {
      
  Auth.currentAuthenticatedUser()
    .then(user => {
      this.onChangeText('username', user.username);
      this.onChangeText('sub', user.attributes.sub);
      this.onChangeText('ProviderEmail', user.attributes.email);
      this._leerdatos();
    })
    .catch(err => console.log(err));
  }
  
  _leerdatos() {
      
        const listEntidades = `
        query list {
            listEntidades(limit: 20, nextToken: null, filter: {ProviderSub: {contains: "` + this.state.sub + `"}}) {
              items {
                ProviderSub
                Entidad
                ProviderEmail
                Dolapikey
                Dolurlapi
                Comentario
              }
            }
          }
        `;
        
        //alert(listEntidades);
        
        this.handleOpenModal();
        API.graphql(graphqlOperation(listEntidades))
        .then((data) => {
            this.setState({ datos: data.data.listEntidades.items });
            this.handleCloseModal();
            //alert(this.state.datos);
          })
          .catch(err => {
            this.handleCloseModal();
            if (! err.message) {
              alert('Error 12: ', err);
            } else {
              alert('Error 22: ', err.message);
            }
          });
    }
  
  
  _add = async () => {
      this.onChangeText('redirectAdd', true);
  }

  _edit = async (entidad) => {
      this.onChangeText("entidadEditar", entidad);
      this.onChangeText('redirectEdit', true);
  }
  
   _delete = async (entidad) => {
      this.onChangeText("entidadBorrar", entidad);
      this.handleClickOpen();
  }
  
  _yesDeleteEntidad = async (entidad) => {
    this.handleClose();
    
    const deleteEntidades = `
      mutation delete {
            deleteEntidades(input: {
               ProviderSub: "` + this.state.sub + `"
               Entidad: "` + entidad + `"
           }) {
             Entidad
           }
          }
        `;

      //alert(deleteEntidades);
      
      this.handleOpenModal();
      await API.graphql(graphqlOperation(deleteEntidades))
      .then(() => {
          this.handleCloseModal();
          this._leerdatos();
        })
      .catch(err => {
        this.handleCloseModal();
        if (! err.message) {
          alert('Error : ', err);
        } else {
          alert('Error : ', err.message);
        }
      });

  }
  
  handleOpenModal () {
    this.setState({ showModal: true });
  }
  handleCloseModal () {
    this.setState({ showModal: false });
  }
  
  handleClickOpen () {
     this.setState({ open: true });
  }
  handleClose () {
    this.setState({ open: false });
  }
  

  render() {
    
    
    const redirectAdd = this.state.redirectAdd;
    if (redirectAdd) {
       return <Redirect push to="/NewEntidad/"/>;
    }
    const redirectEdit = this.state.redirectEdit;
    if (redirectEdit) {
      
      return <Redirect push to={{
                pathname: '/EditEntidad',
                state: { entidad: this.state.entidadEditar }
              }}/>;
    }
    
    const entidadBorrar = this.state.entidadBorrar;
    
    return (

    <div style={{ padding: 40 }}>

      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Seguro quiere Borrar la entidad "+entidadBorrar+" ?" }</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              Si borra la Entidad no podra usar REST API con esta Entidad ...
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary" autoFocus>
            Cancelar
          </Button>
          <Button onClick={() => this._yesDeleteEntidad(entidadBorrar)} color="primary">
            Borrarlo
          </Button>
        </DialogActions>
      </Dialog>

      
      <ReactModal isOpen={this.state.showModal} >
          <img src={require('./loading.gif')} alt="loading..." />
      </ReactModal>
          
      <Typography variant="h5" gutterBottom style={{ marginTop: 20, color: "blue" }}>
        Mis Entidades
      </Typography>

      <Typography >
        Aqui se ubican todas las Entidades utilizadas en su Dolibbar, relacionando las entidades con las Dolapikey y la Url de la API. 
        Asi podra REST API actualizar automaticamente su Dolibarr cada que envie un documento.
        <br /><br />
      </Typography>      
      
      <div style={{ marginTop: 20}}>
        <Button variant="contained" color="default" startIcon={<AddIcon />} onClick={() => this._add()} > Nueva Entidad </Button>
      </div>

      <TableContainer style={{ marginTop: 20}} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow style={{ background: "grey" }}>
            <TableCell align="center">Entidad&nbsp;</TableCell>
            <TableCell align="center">Borrar&nbsp;</TableCell>
            <TableCell align="center">Dolapikey&nbsp;</TableCell>
            <TableCell align="center">Dolurlapi&nbsp;</TableCell>
            <TableCell align="center">Comentario&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.datos.map((row) => (
            <TableRow key={row.name}>
            
              <TableCell component="th" scope="row">
                <IconButton size="small" color="primary" onClick={() => this._edit(row.Entidad)}>
                  <EditIcon />{row.Entidad}
                </IconButton>
              </TableCell>

              <TableCell component="th" scope="row">
                <IconButton size="small" color="primary" onClick={() => this._delete(row.Entidad)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center">{row.Dolapikey}</TableCell>
              <TableCell align="left">{row.Dolurlapi}</TableCell>
              <TableCell align="left">{row.Comentario}</TableCell>
            </TableRow>
          ))}
          
        </TableBody>
      </Table>
    </TableContainer>

  </div>
      
    );
  }
}


import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Button } from '@material-ui/core';
import { Globe } from 'react-bootstrap-icons';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

import { withAuthenticator } from 'aws-amplify-react';
import '@aws-amplify/ui/dist/style.css';

import Inicio from './screens/Inicio';
import Empresa from './screens/Empresa';
import Servicio from './screens/Servicio';
import Prefijos from './screens/Prefijos';
import Entidades from './screens/Entidades';
import NewPrefijo from './screens/NewPrefijo';
import NewEntidad from './screens/NewEntidad';
import EditPrefijo from './screens/EditPrefijo';
import EditEntidad from './screens/EditEntidad';
import Certificado from './screens/Certificado';
import Produccion from './screens/Produccion';
import Pagar from './screens/Pagar';
import Estadisticas from './screens/Estadisticas';
import Alertas from './screens/Alertas';
import Auth from '@aws-amplify/auth';
Amplify.configure(awsconfig);


class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      email: String, 
      username: String };
  }
  
  onChangeText(key, value) {
    this.setState({
      [key]: value
    });
  }
  
  async componentDidMount() {
      
  Auth.currentAuthenticatedUser()
    .then(user => {
      this.onChangeText('email', user.attributes.email);
    })
    .catch(err => console.log(err));
  }
  
  signOut = async () => {
    await Auth.signOut()
    .then(() => {
      console.log('Sign out complete');
    })
    .catch(err => { 
      console.log('Error while signing out!', err);
    });
  }
  
  
  render() {
    return (
      
      <Router>
        <br />
        <nav class="navbar navbar-expand-lg navbar-light bg-primary">
          <a  style={{color:'yellow'}} class="navbar-brand" href="/"><Globe /> <b>FacturaDian</b></a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div class="navbar-nav">
              <Link class="nav-item nav-link" style={{color:'white'}} to="/Empresa/">Empresa</Link>
              <Link class="nav-item nav-link" style={{color:'white'}} to="/Servicio/">Desplegar</Link>
              <Link class="nav-item nav-link" style={{color:'white'}} to="/Prefijos/">Prefijos</Link>
              <Link class="nav-item nav-link" style={{color:'white'}} to="/Entidades/">Entidades</Link>
              <Link class="nav-item nav-link" style={{color:'white'}} to="/Certificado/">Certificado</Link>
              <Link class="nav-item nav-link" style={{color:'white'}} to="/Produccion/">ResolucionDian</Link>
              <Link class="nav-item nav-link" style={{color:'white'}} to="/Pagar/">Suscripcion</Link>
              <Link class="nav-item nav-link" style={{color:'white'}} to="/Estadisticas/">Estadisticas</Link>
              <Link class="nav-item nav-link" style={{color:'white'}} to="/Alertas/">Alertas</Link>
              <Button variant="contained" color="primary" size="small"  onClick={this.signOut}>Salir</Button>
            </div>
          </div>
        </nav>
        
          <h4><center><font color='purple'>{this.state.email}</font></center></h4>


          <Route path="/" exact component={Inicio} />
          <Route path="/Empresa/" component={Empresa} />
          <Route path="/Servicio/" component={Servicio} />
          <Route path="/Prefijos/" component={Prefijos} />
          <Route path="/Entidades/" component={Entidades} />
          <Route path="/NewPrefijo/" component={NewPrefijo} />
          <Route path="/NewEntidad/" component={NewEntidad} />
          <Route path="/EditPrefijo/" component={EditPrefijo} />
          <Route path="/EditEntidad/" component={EditEntidad} />
          <Route path="/Certificado/" component={Certificado} />
          <Route path="/Produccion/" component={Produccion} />
          <Route path="/Pagar/" component={Pagar} />
          <Route path="/Estadisticas/" component={Estadisticas} />
          <Route path="/Alertas/" component={Alertas} />

      </Router>
      
    );
  }
  
}
  
//export default withAuthenticator(App, false );

export default withAuthenticator(App, false, [], null, null, {
  defaultCountryCode: "57",
  header: "Nuevo Registro",
  signUpFields: [
    {
      label: "Usuario",
      key: "username",
      placeholder: "Usa tu email",
      type: "label",
      required: true,
      displayOrder: 1
    },
    {
      label: "Password",
      key: "password",
      placeholder: "Mayusculas Minusculas Numeros Simbolo",
      type: "password",
      required: true,
      displayOrder: 2
    },
    {
      label: "Email",
      key: "email",
      placeholder: "Coloca tu email",
      type: "email",
      required: true,
      displayOrder: 3
    },
    {
      label: "Celular",
      key: "phone_number",
      placeholder: "Celular",
      type: "label",
      required: true,
      displayOrder: 4
    },
  ]
});

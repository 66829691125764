import React from 'react';
import { Link } from "react-router-dom";
import { Typography, Button} from '@material-ui/core';
import Auth from '@aws-amplify/auth';
import { API, graphqlOperation } from 'aws-amplify';
import axios from 'axios';

import ProduccionFoto4 from './fotos/ProduccionFoto4.png';

class Produccion extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      email: String, 
      sub: String,
      accountCode: String,
      SoftwareID: String,
      Parametros: false,
      Bucket: false
    };
  }
  
  onChangeText(key, value) {
    this.setState({
      [key]: value
    });
  }
  
  async componentDidMount() {
  
  Auth.currentAuthenticatedUser()
    .then(user => {
      this.onChangeText('email', user.attributes.email);
      this.onChangeText('sub', user.attributes.sub);
      this._leerdatos();
    })
    .catch(err => console.log(err));
  }
  
  
    _leerdatos() {
      const getParametros = `
        query get {
            getParametros(ProviderSub:"` + this.state.sub + `"){
        				ProviderSub
        				SupplierPartyIdentification
        				SoftwareID
            }
        }
        `; 
        
        API.graphql(graphqlOperation(getParametros))
        .then((data) => {
 
          console.log("Respuesta de parametros: " + JSON.stringify(data));
          if(data.data.getParametros == null) {
            this.onChangeText('Parametros', false);
            this.onChangeText('Bucket',false);
          } else {
            this.onChangeText('Parametros', true);
            this.onChangeText('accountCode', data.data.getParametros.SupplierPartyIdentification);
            this.onChangeText('SoftwareID', data.data.getParametros.SoftwareID);
            const datos = {
              ProvderSub: this.state.sub
            };
            let url = "https://2jr7q76fbi.execute-api.us-east-1.amazonaws.com/prod/consultas";
            axios.post(url, datos)
              .then(response => {
                console.log("bucket responde: " + response.data.bucket);
                if(response.data.bucket === 'true') {
                  this.onChangeText('Bucket', true);
                } else {
                  this.onChangeText('Bucket', false);
                }
              })
              .catch(error => {
                console.log("Error consultando si existe el bucket");
              });
          }
 
        })
          .catch(err => {
            if (! err.message) {
              alert('Error 1 municipios: ', err);
            } else {
              alert('Error 2 municipios: ', err.message);
            }
          }); 
  }

    _activar = () => {
      
        const datos = {
            ProviderEmail: this.state.email,
            ProviderSub: this.state.sub,
            accountCode: this.state.accountCode,
            accountCodeT: this.state.accountCode,
            softwareCode: this.state.SoftwareID
        };
        let url = "https://2jr7q76fbi.execute-api.us-east-1.amazonaws.com/prod/rangos";
  
        axios.post(url, datos)
          .then(response => {
            //alert("Then: " + response);
            alert("1. Utilizando el SoftwareID de Datos Empresa se insertaran los prefijos de produccion Dian a FacturaDian, <br /> 2. En lista Prefijos de Dolibarr actualice los prefijos ");
          })
          .catch(error => {
            //alert("Error: " + error);
            alert("Error de comunicacion con el servidor... intente mas tarde..");
          });

          
  }

    render() {
    return (
      
      <div style={{ padding: 40 }}>

        <Typography variant="h5" gutterBottom style={{ marginTop: 20, color: "blue" }}>
              Actualizar Resolucion DIAN en FacturaDian
        </Typography>
      
        Ahora es momento de obtener la resolucion, la clave tecnica, y vencimientos de los prefijos
        de produccion que acabas de asignar al SofWare. Estos datos se cargaran automaticamente a los prefijos de FacturaDian. 
        Tambien deberas ejecutar este paso cuando cambies a un nuevo rango de numeracion porque se tendria una nueva 
        resolucion de rangos de numeracion.
        <br /><br />
        El boton estara activado si ya registraste los <Link to="/Empresa/">Datos-Empresa</Link> del menu principal y 
        ya desplegaste tu Dolibarr-Facturacion en <Link to="/Servicio/">Desplegando mi Dolibarr</Link> del menu principal.
        <br /><br />
        <center>
          <Button variant="contained" color="primary" size="small" disabled={!this.state.Parametros || !this.state.Bucket } onClick={this._activar}>
            Actualizar FacturaDian con los prefijos de produccion de la Dian
          </Button>
        </center>
        
        <br /><br />
        Despues de actualizar FacturaDian con los prefijos de produccion de la DIAN, adiciona los prefijos 
        de las notas credito y debito en <Link to="/Prefijos/">Prefijos</Link> del menu principal, normalmente 
        se usa el prefijo NC para las notas credito y el prefijo ND para las notas debito, pero igual podras 
        usar los prefijos para las notas que mas desees.
        <br /><br />
        Ahora actualiza los prefjos de produccion de la DIAN y los prefijos de las notas en Dolibarr desde 
        el menu FacturaDian, asi tendremos todo sincronizado.
        <br /><br />
        <img src={ProduccionFoto4} width="500" height="300" alt='foto' />

        
      </div>

    );
  }
}

export default Produccion;

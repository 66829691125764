import React from 'react';
import { FormErrors } from './FormErrors';
import './Form.css';

import '../App.css';
import { Redirect } from 'react-router';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Auth from '@aws-amplify/auth';
import { API, graphqlOperation } from 'aws-amplify';
import ReactModal from 'react-modal';

export default class NewPrefijo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      showModal: false,
      username: String, 
      sub: String,
      ProviderEmail: String,
      
      Prefijo: String,
      invoiceAuthorization: String,
      desde: String,
      hasta: String,
      startDate: String,
      endDate: String,
      emailDespacho: String,
      emailCopia: String,
      ClaveTecnica: String,
      Ambiente: String,
      
      formErrors: {
        Prefijo: '', 
        invoiceAuthorization: '', 
        desde: '',
        hasta: '',
        startDate: '',
        endDate: '',
        emailDespacho: '',
        emailCopia: '',
        ClaveTecnica: '',
        Ambiente: ''
      },
      formValid: false,
      
      PrefijoValid: false,
      invoiceAuthorizationValid: false,
      desdeValid: false,
      hastaValid: false,
      startDateValid: false,
      endDateValid: false,
      emailDespachoValid: false,
      emailCopiaValid: false,
      ClaveTecnicaValid: false,
      AmbienteValid: false
    };
    
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  
  onChangeText(key, value) {
    this.setState({
      [key]: value
    });
  }
  
  async componentDidMount() {
      
  Auth.currentAuthenticatedUser()
    .then(user => {
      this.onChangeText('username', user.username);
      this.onChangeText('sub', user.attributes.sub);
      this.onChangeText('ProviderEmail', user.attributes.email);
    })
    .catch(err => console.log(err));
  }
  
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
                  () => { this.validateField(name, value) });
  }
  
  
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    
    let PrefijoValid = this.state.PrefijoValid;
    let invoiceAuthorizationValid = this.state.invoiceAuthorizationValid;
    let desdeValid = this.state.desdeValid;
    let hastaValid = this.state.hastaValid;
    let startDateValid = this.state.startDateValid;
    let endDateValid = this.state.endDateValid;
    let emailDespachoValid = this.state.emailDespachoValid;
    let emailCopiaValid = this.state.emailCopiaValid;
    let ClaveTecnicaValid = this.state.ClaveTecnicaValid;
    let AmbienteValid = this.state.AmbienteValid;

    switch(fieldName) {
      case 'Prefijo':
        PrefijoValid  = value.match(/^([A-Za-z0-9])+$/);
        fieldValidationErrors.Prefijo = PrefijoValid ? '' : 'Solo A-Za-z0-9';
        break;
      case 'invoiceAuthorization':
        invoiceAuthorizationValid = value.length >= 10;
        fieldValidationErrors.invoiceAuthorization = invoiceAuthorizationValid ? '': 'Mas de 10 caracteres';
        break;
      case 'desde':
        desdeValid =  value.match(/^\d+$/);
        fieldValidationErrors.desde = desdeValid ? '': 'Solo numeros';
        break;
      case 'hasta':
        hastaValid = value.match(/^\d+$/);
        fieldValidationErrors.hasta = hastaValid ? '': 'Solo numeros';
        break;
      case 'startDate':
        startDateValid = value.match(/^(\d{4}-\d{2}-\d{2})$/);
        fieldValidationErrors.startDate = startDateValid ? '': 'Fecha formato aaaa-mm-dd';
        break;
      case 'endDate':
        endDateValid = value.match(/^(\d{4}-\d{2}-\d{2})$/);
        fieldValidationErrors.endDate = endDateValid ? '': 'Fecha formato aaaa-mm-dd';
        break;
      case 'emailDespacho':
        emailDespachoValid = value.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
        fieldValidationErrors.emailDespacho = emailDespachoValid ? '': 'Un email valido';
        break;
      case 'emailCopia':
        emailCopiaValid = value.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
        fieldValidationErrors.emailCopia = emailCopiaValid ? '': 'Un email valido';
        break; 
      case 'ClaveTecnica':
        ClaveTecnicaValid = value.length >= 10;
        fieldValidationErrors.ClaveTecnica = ClaveTecnicaValid ? '': 'Mas de 10 caracteres';
        break;         
case 'Ambiente':
        AmbienteValid = value.match(/^\d{1}$/);
        fieldValidationErrors.Ambiente = AmbienteValid ? '': '1=Produccion o 2=Habilitacion';
        break; 
      default:
        break;
    }
    this.setState({formErrors: fieldValidationErrors,
                    PrefijoValid: PrefijoValid,
                    invoiceAuthorizationValid: invoiceAuthorizationValid,
                    desdeValid: desdeValid,
                    hastaValid: hastaValid,
                    startDateValid: startDateValid,
                    endDateValid: endDateValid,
                    emailDespachoValid: emailDespachoValid,
                    emailCopiaValid: emailCopiaValid,
                    ClaveTecnicaValid: ClaveTecnicaValid,
                    AmbienteValid:AmbienteValid
                  }, this.validateForm);
  }
  
  validateForm() {
    this.setState({ 
      formValid: 
        this.state.PrefijoValid && 
        this.state.invoiceAuthorizationValid && 
        this.state.desdeValid &&
        this.state.hastaValid &&
        this.state.startDateValid &&
        this.state.endDateValid &&
        this.state.emailDespachoValid &&
        this.state.emailCopiaValid &&
        this.state.ClaveTecnicaValid &&
        this.state.AmbienteValid
    });
  }

  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }
  
  handleOpenModal () {
    this.setState({ showModal: true });
  }
  
  handleCloseModal () {
    this.setState({ showModal: false });
  }
  
   _save = (sub) => {
        const createPrefijos = `
            mutation create {
              createPrefijos(input: {
                ProviderSub: "` + this.state.sub + `"
                Prefijo: "` + this.state.Prefijo + `"
                invoiceAuthorization: "` + this.state.invoiceAuthorization + `"
                desde: "` + this.state.desde + `"
                hasta: "` + this.state.hasta + `"
                startDate: "` + this.state.startDate + `"
                endDate: "` + this.state.endDate + `"
                emailDespacho: "` + this.state.emailDespacho + `"
                emailCopia: "` + this.state.emailCopia + `"
                ClaveTecnica: "` + this.state.ClaveTecnica + `"
                Ambiente: "` + this.state.Ambiente + `"
              }) {
                Prefijo
              }
            }
        `;
        
        console.log(createPrefijos);
        
        this.handleOpenModal();
        API.graphql(graphqlOperation(createPrefijos))
        .then(() => {
            this.handleCloseModal();
            this.onChangeText('redirect', true);
          })
        .catch(err => {
          this.handleCloseModal();
          if (! err.message) {
            console.log('Error when signing in: ', err);
          } else {
            console.log('Error when signing in: ', err.message);
          }
        });

   }
   
  render() {
    
      const { redirect } = this.state;
      if (redirect) {
         return <Redirect push to="/Prefijos"/>;
      }
      
    return (
      
<div style={{ padding: 40 }}>
      
          <ReactModal isOpen={this.state.showModal} >
              <img src={require('./loading.gif')} alt="loading..." />
          </ReactModal>
        
          <div className="panel panel-default">
            <FormErrors formErrors={this.state.formErrors} />
          </div>
          
          <Typography variant="h4" gutterBottom style={{ marginTop: 20, color: "blue" }}>
            Adicionar prefijo
          </Typography>
          
          <Grid style={{ marginTop: 20 }} container spacing={3}>
          
              <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.Prefijo)}`}>
                  <label htmlFor="Prefijo">Prefijo</label>
                  <input type="text" required className="form-control" name="Prefijo"
                    placeholder="Solo caracteres autorizados"
                    value={this.state.Prefijo}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.invoiceAuthorization)}`}>
                  <label htmlFor="invoiceAuthorization">Autorizacion Dian</label>
                  <input type="text" required className="form-control" name="invoiceAuthorization"
                    placeholder="Solo caracteres autorizados"
                    value={this.state.invoiceAuthorization}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.desde)}`}>
                  <label htmlFor="desde">Desde el Numero</label>
                  <input type="text" required className="form-control" name="desde"
                    placeholder="Solo numeros"
                    value={this.state.desde}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.hasta)}`}>
                  <label htmlFor="hasta">Hasta el Numero</label>
                  <input type="text" required className="form-control" name="hasta"
                    placeholder="Solo numeros"
                    value={this.state.hasta}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.startDate)}`}>
                  <label htmlFor="startDate">Desde la fecha</label>
                  <input type="text" required className="form-control" name="startDate"
                    placeholder="Formato aaaa-mm-dd"
                    value={this.state.startDate}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.endDate)}`}>
                  <label htmlFor="endDate">Hasta la Fecha</label>
                  <input type="text" required className="form-control" name="endDate"
                    placeholder="Formato aaaa-mm-dd"
                    value={this.state.endDate}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.emailDespacho)}`}>
                  <label htmlFor="emailDespacho">Despachar desde el email </label>
                  <input type="text" required className="form-control" name="emailDespacho"
                    placeholder="Un email valido"
                    value={this.state.emailDespacho}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.emailCopia)}`}>
                  <label htmlFor="emailCopia">Copia de email a</label>
                  <input type="text" required className="form-control" name="emailCopia"
                    placeholder="Un email valido"
                    value={this.state.emailCopia}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>
              
               <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.ClaveTecnica)}`}>
                  <label htmlFor="ClaveTecnica">Clave Tecnica Dian del prefijo</label>
                  <input type="text" required className="form-control" name="ClaveTecnica"
                    placeholder="Solo caracteres validos"
                    value={this.state.ClaveTecnica}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>

               <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.Ambiente)}`}>
                  <label htmlFor="Ambiente">1=produccion 2=Habilitacion</label>
                  <input type="text" required className="form-control" name="Ambiente"
                    placeholder="Solo un digito"
                    value={this.state.Ambiente}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>
              
            </Grid>  

            <button type="submit" className="btn btn-primary" disabled={!this.state.formValid} onClick={this._save}>Guardar</button>          

      </div>
      
    );
  }
}


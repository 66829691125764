import React from 'react';
import { FormErrors } from './FormErrors';
import './Form.css';

import '../App.css';
import { Redirect } from 'react-router';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Auth from '@aws-amplify/auth';
import { API, graphqlOperation } from 'aws-amplify';
import ReactModal from 'react-modal';

export default class EditEntidad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectPrefijos: false,
      showModal: false,
      username: String, 
      sub: String,
      ProviderEmail: String,
      
      Entidad: String,
      Dolapikey: String,
      Dolurlapi: String,
      Comentario: String,

      formErrors: {
        Entidad: '', 
        Dolapikey: '', 
        Dolurlapi: '',
        Comentario: ''
      },
      formValid: false,
      
      PrefijoValid: false,
      invoiceAuthorizationValid: false,
      desdeValid: false
    };
    
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  
  onChangeText(key, value) {
    this.setState({
      [key]: value
    });
  }
  
  async componentDidMount() {
    
  console.log(this.props.location.state.prefijo);
      
  Auth.currentAuthenticatedUser()
    .then(user => {
      this.onChangeText('username', user.username);
      this.onChangeText('sub', user.attributes.sub);
      this.onChangeText('ProviderEmail', user.attributes.email);
      this.onChangeText('Entidad', this.props.location.state.entidad);
      this._leerdatos(user.attributes.sub,this.props.location.state.entidad);
    })
    .catch(err => console.log(err));
  }
  
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
                  () => { this.validateField(name, value) });
  }
  
  
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    
    let DolapikeyValid = this.state.DolapikeyValid;
    let DolurlapiValid = this.state.DolurlapiValid;
    let ComentarioValid = this.state.ComentarioValid;

    switch(fieldName) {
      case 'Dolapikey':
        DolapikeyValid = value.length >= 2;
        fieldValidationErrors.invoiceAuthorization = DolapikeyValid ? '': 'NA si no aplica';
        break;
      case 'Dolurlapi':
        DolurlapiValid =  value.length >= 2;
        fieldValidationErrors.desde = DolurlapiValid ? '': 'NA si no aplica';
        break;
      case 'Comentario':
        ComentarioValid =  value.length >= 2;
        fieldValidationErrors.desde = ComentarioValid ? '': 'NA si no aplica';
        break;
      default:
        break;
    }
    this.setState({formErrors: fieldValidationErrors,
                    DolapikeyValid: DolapikeyValid,
                    DolurlapiValid: DolurlapiValid,
                    ComentarioValid: ComentarioValid,
                  }, this.validateForm);
  }
  
  validateForm() {
    this.setState({ 
      formValid: 
        this.state.DolapikeyValid && 
        this.state.DolurlapiValid &&
        this.state.ComentarioValid
    });
  }

  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }
  
  handleOpenModal () {
    this.setState({ showModal: true });
  }
  
  handleCloseModal () {
    this.setState({ showModal: false });
  }
  
  
  _leerdatos(sub,entidad) {
    
        const getEntidades = `
          query list {
            getEntidades(ProviderSub: "` + sub + `", Entidad: "` + entidad + `") {
                  Entidad
                  Dolapikey
                  Dolurlapi
                  Comentario
            }
          }
        `;
        
        this.handleOpenModal();
        
        API.graphql(graphqlOperation(getEntidades))
        .then((data) => {
            this.setState({ datos: data.data.getEntidades });
            console.log(this.state.datos);
            this.setState({ Entidad: this.state.datos.Entidad });
            this.setState({ Dolapikey: this.state.datos.Dolapikey });
            this.setState({ Dolurlapi: this.state.datos.Dolurlapi });
            this.setState({ Comentario: this.state.datos.Comentario });
              
            this.handleCloseModal();
          })
          .catch(err => {
            this.handleCloseModal();
            if (! err.message) {
              alert('Error 1 getEntidades: ', err);
            } else {
              alert('Error 2: ', err.message);
            }
          });

    }
  
   _update = (entidad) => {
    
        const updateEntidades = `
            mutation create {
              updateEntidades(input: {
                ProviderSub: "` + this.state.sub + `"
                Entidad: "` + this.state.Entidad + `"
                Dolapikey: "` + this.state.Dolapikey + `"
                Dolurlapi: "` + this.state.Dolurlapi + `"
                Comentario: "` + this.state.Comentario + `"
              }) {
                Entidad
              }
            }
        `;
        
        console.log(updateEntidades);
        
        this.handleOpenModal();
        API.graphql(graphqlOperation(updateEntidades))
        .then(() => {
            this.handleCloseModal();
            this.onChangeText('redirectEntidades', true);
          })
        .catch(err => {
          this.handleCloseModal();
          if (! err.message) {
            console.log('Error when signing in: ', err);
          } else {
            console.log('Error when signing in: ', err.message);
          }
        });
        
   }
   
  render() {
    
      const redirectEntidades = this.state.redirectEntidades;
      if (redirectEntidades) {
         return <Redirect push to="/Entidades"/>;
      }
      
    return (
      
<div style={{ padding: 40 }}>
      
          <ReactModal isOpen={this.state.showModal} >
              <img src={require('./loading.gif')} alt="loading..." />
          </ReactModal>
        
          <div className="panel panel-default">
            <FormErrors formErrors={this.state.formErrors} />
          </div>
          
          <Typography variant="h4" gutterBottom style={{ marginTop: 20, color: "blue" }}>
            Editar Entidad
          </Typography>
          
          <Grid style={{ marginTop: 20 }} container spacing={3}>
          
              <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.Entidad)}`}>
                  <label htmlFor="Entidad">Entidad</label>
                  <input disabled type="text" required className="form-control" name="Entidad"
                    placeholder="Solo numeros"
                    value={this.state.Entidad}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.Dolapikey)}`}>
                  <label htmlFor="Dolapikey">API KEY</label>
                  <input type="text" required className="form-control" name="Dolapikey"
                    placeholder="NA si no aplica"
                    value={this.state.Dolapikey}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>

               <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.Dolurlapi)}`}>
                  <label htmlFor="Dolurlapi">URL de la REST API</label>
                  <input type="text" required className="form-control" name="Dolurlapi"
                    placeholder="NA si no aplica"
                    value={this.state.Dolurlapi}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.Comentario)}`}>
                  <label htmlFor="Comentario">Comentario</label>
                  <input type="text" required className="form-control" name="Comentario"
                    placeholder="NA si no aplica"
                    value={this.state.Comentario}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>
              
            </Grid>  

            <Typography variant="h7" gutterBottom style={{ marginTop: 20, color: "blue" }}>
                  Repase todos los campos para activar el Boton <br />
             </Typography>
            
            <button type="submit" className="btn btn-primary" disabled={!this.state.formValid} onClick={this._update}>Actualizar</button>          

      </div>
      
    );
  }
}


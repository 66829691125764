import React from 'react';
import { FormErrors } from './FormErrors';
import './Form.css';

import '../App.css';
import { Redirect } from 'react-router';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Auth from '@aws-amplify/auth';
import { API, graphqlOperation } from 'aws-amplify';
import ReactModal from 'react-modal';

export default class NewEntidad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      showModal: false,
      username: String, 
      sub: String,
      ProviderEmail: String,
      
      Entidad: String,
      Dolapikey: String,
      Dolurlapi: String,
      Comentario: String,

      formErrors: {
        Entidad: '', 
        Dolapikey: '', 
        Dolurlapi: '',
        Comentario: ''
      },
      formValid: false,
      
      EntidadValid: false,
      DolapikeyValid: false,
      DolurlapiValid: false,
      ComentarioValid: false
    };
    
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  
  onChangeText(key, value) {
    this.setState({
      [key]: value
    });
  }
  
  async componentDidMount() {
      
  Auth.currentAuthenticatedUser()
    .then(user => {
      this.onChangeText('username', user.username);
      this.onChangeText('sub', user.attributes.sub);
      this.onChangeText('ProviderEmail', user.attributes.email);
    })
    .catch(err => console.log(err));
  }
  
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
                  () => { this.validateField(name, value) });
  }
  
  
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    
    let EntidadValid = this.state.EntidadValid;
    let DolapikeyValid = this.state.DolapikeyValid;
    let DolurlapiValid = this.state.DolurlapiValid;
    let ComentarioValid = this.state.ComentarioValid;

    switch(fieldName) {
      case 'Entidad':
        EntidadValid  = value.match(/^\d+$/);
        fieldValidationErrors.Entidad = EntidadValid ? '' : 'Solo numeros';
        break;
      case 'Dolapikey':
        DolapikeyValid = value.length >= 2;
        fieldValidationErrors.invoiceAuthorization = DolapikeyValid ? '': 'NA si no aplica';
        break;
      case 'Dolurlapi':
        DolurlapiValid =  value.length >= 2;
        fieldValidationErrors.desde = DolurlapiValid ? '': 'NA si no aplica';
        break;
      case 'Comentario':
        ComentarioValid =  value.length >= 2;
        fieldValidationErrors.desde = ComentarioValid ? '': 'NA si no aplica';
        break;        
      default:
        break;
    }
    this.setState({formErrors: fieldValidationErrors,
                    EntidadValid: EntidadValid,
                    DolapikeyValid: DolapikeyValid,
                    DolurlapiValid: DolurlapiValid,
                    ComentarioValid: ComentarioValid
                  }, this.validateForm);
  }
  
  validateForm() {
    this.setState({ 
      formValid: 
        this.state.EntidadValid && 
        this.state.DolapikeyValid && 
        this.state.DolurlapiValid &&
        this.state.ComentarioValid
    });
  }

  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }
  
  handleOpenModal () {
    this.setState({ showModal: true });
  }
  
  handleCloseModal () {
    this.setState({ showModal: false });
  }
  
   _save = (sub) => {
        const createEntidades = `
            mutation create {
              createEntidades(input: {
                ProviderSub: "` + this.state.sub + `"
                ProviderEmail: "` + this.state.email + `"
                Entidad: "` + this.state.Entidad + `"
                Dolapikey: "` + this.state.Dolapikey + `"
                Dolurlapi: "` + this.state.Dolurlapi + `"
                Comentario: "` + this.state.Comentario + `"
              }) {
                Entidad
              }
            }
        `;
        
        //alert(createEntidades);
        
        this.handleOpenModal();
        API.graphql(graphqlOperation(createEntidades))
        .then(() => {
            this.handleCloseModal();
            this.onChangeText('redirect', true);
          })
        .catch(err => {
          this.handleCloseModal();
          if (! err.message) {
            alert('Error when signing in: ', err);
          } else {
            alert('Error when signing in: ', err.message);
          }
        });

   }
   
  render() {
    
      const { redirect } = this.state;
      if (redirect) {
         return <Redirect push to="/Entidades"/>;
      }
      
    return (
      
<div style={{ padding: 40 }}>
      
          <ReactModal isOpen={this.state.showModal} >
              <img src={require('./loading.gif')} alt="loading..." />
          </ReactModal>
        
          <div className="panel panel-default">
            <FormErrors formErrors={this.state.formErrors} />
          </div>
          
          <Typography variant="h4" gutterBottom style={{ marginTop: 20, color: "blue" }}>
            Adicionar Entidad
          </Typography>
          
          <Grid style={{ marginTop: 20 }} container spacing={3}>
          
              <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.Entidad)}`}>
                  <label htmlFor="Entidad">Entidad</label>
                  <input type="text" required className="form-control" name="Entidad"
                    placeholder="El numero de la Entidad en Dolibarr"
                    value={this.state.Entidad}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.Dolapikey)}`}>
                  <label htmlFor="Dolapikey">API KEY</label>
                  <input type="text" required className="form-control" name="Dolapikey"
                    placeholder="API KEY"
                    value={this.state.Dolapikey}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.Dolurlapi)}`}>
                  <label htmlFor="Dolurlapi">URL de la REST API</label>
                  <input type="text" required className="form-control" name="Dolurlapi"
                    placeholder="URL de la REST API"
                    value={this.state.Dolurlapi}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.Comentario)}`}>
                  <label htmlFor="Comentario">Comentario</label>
                  <input type="text" required className="form-control" name="Comentario"
                    placeholder="Comentario"
                    value={this.state.Comentario}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>
              

            </Grid>  

            <button type="submit" className="btn btn-primary" disabled={!this.state.formValid} onClick={this._save}>Guardar</button>          

      </div>
      
    );
  }
}


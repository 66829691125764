import React from 'react';
//import { Link } from "react-router-dom";
//import { Typography, Avatar, Button } from '@material-ui/core';
import { Typography, Button} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Auth from '@aws-amplify/auth';
import { API, graphqlOperation } from 'aws-amplify';
import axios from 'axios';

class Servicio extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      email: String, 
      username: String, 
      sub: String,
      Parametros: false,
      Bucket: false
    };
  }
  
  onChangeText(key, value) {
    this.setState({
      [key]: value
    });
  }
  
  async componentDidMount() {
      
  Auth.currentAuthenticatedUser()
    .then(user => {
      this.onChangeText('email', user.attributes.email);
      this.onChangeText('sub', user.attributes.sub);
      this.onChangeText('username', user.username);
      this._leerdatos();
    })
    .catch(err => console.log(err));
  }
  
  _leerdatos() {
      const getParametros = `
        query get {
            getParametros(ProviderSub:"` + this.state.sub + `") {
        				ProviderSub
            }
        }
        `; 
        
      API.graphql(graphqlOperation(getParametros))
      .then((data) => {
          console.log("Respuesta de parametros: " + JSON.stringify(data));
          if(data.data.getParametros == null) {
            this.onChangeText('Parametros', false);
            this.onChangeText('Bucket',false);
          } else {
            this.onChangeText('Parametros', true);
            const datos = {
              ProvderSub: this.state.sub
            };
            let url = "https://2jr7q76fbi.execute-api.us-east-1.amazonaws.com/prod/consultas";
            axios.post(url, datos)
              .then(response => {
                console.log("bucket responde: " + response.data.bucket);
                if(response.data.bucket === 'true') {
                  this.onChangeText('Bucket', true);
                } else {
                  this.onChangeText('Bucket', false);
                }
              })
              .catch(error => {
                console.log("Error consultando si existe el bucket");
              });

          }

      })
        .catch(err => {
          if (! err.message) {
            alert('Error 1 municipios: ', err);
          } else {
            alert('Error 2 municipios: ', err.message);
          }
        }); 

  }
  
  _activar = () => {

      const datos = {
          ProviderEmail: this.state.email,
          Sub: this.state.sub,
      };
      let url = "https://2jr7q76fbi.execute-api.us-east-1.amazonaws.com/prod/dian2lanzarservicio";

      axios.post(url, datos)
        .then(response => {
          //alert("Then: " + response);
          alert("Servicio Creado, recibira un Email con los datos de acceso");
        })
        .catch(error => {
          //alert("Error: " + error);
          alert("Servicio Creado, recibira un Email con los datos de acceso");
        });
  }
  
  render() {
    
    return (
      <div style={{ padding: 40 }}>
        <Typography variant="h5" gutterBottom style={{ marginTop: 20, color: "blue" }}>
            Desplegar ERP/CRM
      </Typography>
      <Typography variant="h6" gutterBottom style={{ marginTop: 20 }}>
            Ahora que llegaste a este punto, puedes desplegar tu propio ERP/CRM para que puedas crear
            tus productos, tus clientes, tus facturas, etc.
            <br /><br />
            Tambien aprobaremos tu cuenta de FacturaDian ante la DIAN enviando en modo pruebas (Habilitacion) 
            10 facturas, 1 Nota Credito y una Nota Debito.  Pero No nos adelantemos, por ahora lanza tu solucion 
            ERP/CRM y familiarizate con este fabuloso programa de facturacion.
      </Typography>
      <br />

      <Typography variant="h6" gutterBottom style={{ marginTop: 20 }}>
            El boton <b>DESPLEGAR TU ERP/CRM</b> solo estara activo si ya ingresaste los datos de la Empresa y 
            No has desplegado tu ERP/CRM aun. 
      </Typography>
        <br />
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item xs>
            <center>
            <Button variant="contained" color="primary" size="small" disabled={!this.state.Parametros || this.state.Bucket } onClick={this._activar}>
              Desplegar ERP/CRM
            </Button>
            </center>
          </Grid>
        </Grid> 

        <Typography >
          <br /><br />
          El email con los datos de acceso y las credenciales se enviaran al email que colocaste al abrir la 
          cuenta en <b>portal.facturadian.com</b> (No se enviaran al email que registraste en los datos de la empresa). 
          <br /><br />
          Tambien recibiras un segundo email de AWS, acepta  enviar los email a los clientes tanto en modo pruebas 
          como en produccion dando clic sobre un gran texto azul que te aparece en el centro del correo.
          <br /><br />
        </Typography>
        
        
        
        

      </div>

    );
  }
}

export default Servicio;


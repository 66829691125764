import React from 'react';
import './Form.css';

import {Typography} from '@material-ui/core';

import axios from 'axios';
import Auth from '@aws-amplify/auth';

class Certificado extends React.Component {

  constructor(props) {
    super(props);
    this.state ={
      file:null,
      mensaje: String,
      sub: String,
      clave: String,
      formErrors: {
        clave: '', 
      },
      formValid: false,
      claveValid: false
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
  }
  
  async componentDidMount() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.onChangeText('sub', user.attributes.sub);
      })
      .catch(err => console.log(err));
  }

  onChangeText(key, value) {
    this.setState({
      [key]: value
    });
  }
  
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
                  () => { this.validateField(name, value) });
  }

validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    
    let claveValid = this.state.claveValid;

    switch(fieldName) {
      case 'clave':
        claveValid  = value.match(/^([A-Za-z0-9._-])+$/);
        fieldValidationErrors.clave = claveValid ? '' : 'Solo caracteres autorizados';
        break;
      default:
        break;
    }
    this.setState({formErrors: fieldValidationErrors,
          claveValid: claveValid,
    }, this.validateForm);
  }
  
  validateForm() {
    this.setState({ 
      formValid: 
        this.state.claveValid
    });
  }
  
  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }
  
  
  onFormSubmit(e){
    e.preventDefault();
    this.fileUpload(this.state.file).then((response)=>{
      console.log(response.data);
      this.setState({mensaje:response.data});
      
    });
  }

  onChange(event) {
    
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({file:reader.result.split(',')[1]});
      };
    }

    //this.setState({file:event.target.files[0]});

  }

  fileUpload(file){
    const url = 'https://2jr7q76fbi.execute-api.us-east-1.amazonaws.com/prod/dian2certificado';
    var content = JSON.stringify({
      certificado: this.state.file,
      sub: this.state.sub,
      clave: this.state.clave
    });
    const config = {
        headers: {
            'content-type': 'application/json'
        }
    };
    return  axios.post(url,content,config);
  }

  render() {
    return (
      <div style={{ padding: 40 }}>
      
      <Typography variant="h5" gutterBottom style={{ marginTop: 20, color: "blue" }}>
        Certificado digital
      </Typography>
      
      <Typography variant="h6" gutterBottom style={{ marginTop: 10}}>
        Una vez obtenga el certificado digital en formato .pfx y su clave si el certificado la requiere,
        carga el certificado y luego ingresa la clave por separado. Consulta en detalle como puedes obtener 
        este certificado en el <b>PASO 5 - Certificado de firma electronica</b>.
        <br /><br />
      </Typography>
              
      <table>
        <tr>
          <td>
            <form onSubmit={this.onFormSubmit}>

              <input style={{ marginTop: 30}} type="file" onChange={this.onChange} />
              <br /><br /><br />
              <div className={`form-group ${this.errorClass(this.state.formErrors.clave)}`}>
                <label htmlFor="clave">Clave del certificado, coloque NA si no usa clave</label>
                <input type="text" required className="form-control" name="clave"
                  placeholder="La palabra NA si no tiene clave su certificado"
                  value={this.state.clave}
                  onChange={this.handleUserInput}  />
              </div>

              
              <button type="submit">Subir certificado y clave</button>
            </form>
        </td>
        <td>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </td>
        <td>
            <Typography variant="h5" gutterBottom style={{ marginTop: 24, color: "blue" }}>
              {this.state.mensaje}
            </Typography>
        </td>
        </tr>
      </table>
      
      
      
        
     </div> 
      
   );
  }
}


export default Certificado;
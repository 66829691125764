import React, { Component } from 'react';
import { FormErrors } from './FormErrors';
import './Form.css';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Redirect } from 'react-router';
import ReactModal from 'react-modal';
import { API, graphqlOperation } from 'aws-amplify';
import Auth from '@aws-amplify/auth';

class Certificado extends Component {
  constructor (props) {
    super(props);
    this.state = {
      redirect: false,
      showModal: false,
      accion: String,
      username: String,
      sub: String,
      ProviderEmail: String,
      datos: [],
      municipios: [],
      municipios2: [],

      SupplierName: String,
      SupplierPartyIdentification: String,
      SupplierPartyDV:String,
      SupplierAddressContactElectronicMail:String,
      SupplierAddressContactTelephone:String,
      SupplierLine:String,
      
      PinDian:String,
      SoftwareID:String,
      TestSetId:String,
      Municipio:String,

      SupplierAdditionalAccountID:String,
      SupplierID:String,
      SupplierTaxLevelCode:String,
      SupplierTaxLevelCodelistName:String,
      
      Passwordapp:String,
      Dolurlapi:String,
      Configpath:String,
      Tipo:String,
      
      formErrors: {
        SupplierName: '', 
        SupplierPartyIdentification: '', 
        SupplierPartyDV: '',
        SupplierAddressContactElectronicMail: '',
        SupplierAddressContactTelephone: '',
        SupplierLine: '',
        
        PinDian:'',
        SoftwareID:'',
        TestSetId:'',
        Municipio:'',

        SupplierAdditionalAccountID:'',
        SupplierID:'',
        SupplierTaxLevelCode:'',
        SupplierTaxLevelCodelistName:'',
        
        Passwordapp:'',
        Dolurlapi:'',
        Configpath:'',
        Tipo:'',
        
      },
      formValid: false,
      
      SupplierNamelValid: false,
      SupplierPartyIdentificationValid: false,
      SupplierPartyDVValid: false,
      SupplierAddressContactElectronicMailValid: false,
      SupplierAddressContactTelephoneValid: false,
      SupplierLineValid: false,
      
      PinDianValid: false,
      SoftwareIDValid: false,
      TestSetIdValid: false,
      MunicipioValid: false,

      SupplierAdditionalAccountIDValid: false,
      SupplierIDValid: false,
      SupplierTaxLevelCodeValid: false,
      SupplierTaxLevelCodelistNameValid: false,
      
      PasswordappValid: false,
      DolurlapiValid: false,
      ConfigpathValid: false,
      TipoValid: false
    };
    
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  
  async componentDidMount() {

    Auth.currentAuthenticatedUser()
      .then(user => {
        this.onChangeText('username', user.username);
        this.onChangeText('sub', user.attributes.sub);
        this.onChangeText('ProviderEmail', user.attributes.email);
        this._leerdatos();
      })
      .catch(err => console.log(err));
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
    () => { this.validateField(name, value) });
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    
    let SupplierNamelValid = this.state.SupplierNamelValid;
    let SupplierPartyIdentificationValid = this.state.SupplierPartyIdentificationValid;
    let SupplierPartyDVValid = this.state.SupplierPartyDVValid;
    let SupplierAddressContactElectronicMailValid = this.state.SupplierAddressContactElectronicMailValid;
    let SupplierAddressContactTelephoneValid = this.state.SupplierAddressContactTelephoneValid;
    let SupplierLineValid = this.state.SupplierLineValid;
    
    let PinDianValid = this.state.PinDianValid;
    let SoftwareIDValid = this.state.SoftwareIDValid;
    let TestSetIdValid = this.state.TestSetIdValid;
    let MunicipioValid = this.state.MunicipioValid;

    let SupplierAdditionalAccountIDValid = this.state.SupplierAdditionalAccountIDValid;
    let SupplierIDValid = this.state.SupplierIDValid;
    let SupplierTaxLevelCodeValid = this.state.SupplierTaxLevelCodeValid;
    let SupplierTaxLevelCodelistNameValid = this.state.SupplierTaxLevelCodelistNameValid;
    
    let PasswordappValid = this.state.PasswordappValid;
    let DolurlapiValid = this.state.DolurlapiValid;
    let ConfigpathValid = this.state.ConfigpathValid;
    let TipoValid = this.state.TipoValid;
    
    switch(fieldName) {
      case 'SupplierName':
        SupplierNamelValid  = value.match(/^([A-Za-z0-9 ._-])+$/);
        fieldValidationErrors.SupplierName = SupplierNamelValid ? '' : 'Solo caracteres autorizados';
        break;
      case 'SupplierPartyIdentification':
        SupplierPartyIdentificationValid = value.match(/^\d{5,10}$/);
        fieldValidationErrors.SupplierPartyIdentification = SupplierPartyIdentificationValid ? '': ' Solo de 5 a 10 numeros  ';
        break;
      case 'SupplierPartyDV':
        SupplierPartyDVValid = value.match(/^\d$/);
        fieldValidationErrors.SupplierPartyDV = SupplierPartyDVValid ? '': 'Solo un numero';
        break;
      case 'SupplierAddressContactElectronicMail':
        SupplierAddressContactElectronicMailValid = value.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,9}$/);
        fieldValidationErrors.SupplierAddressContactElectronicMail = SupplierAddressContactElectronicMailValid ? '': 'Un correo valido';
        break;
      case 'SupplierAddressContactTelephone':
        SupplierAddressContactTelephoneValid = value.match(/^([0-9()+])+$/);
        fieldValidationErrors.SupplierAddressContactTelephone = SupplierAddressContactTelephoneValid ? '': 'Solo numeros';
        break;
      case 'SupplierLine':
        SupplierLineValid = value.match(/^([A-Za-z0-9 .#_-])+$/);
        fieldValidationErrors.SupplierLine = SupplierLineValid ? '': 'Solo caracteres validos';
        break;
        
      case 'PinDian':
        PinDianValid = value.match(/^\d+$/);
        fieldValidationErrors.PinDian = PinDianValid ? '': 'Solo caracteres validos';
        break; 
      case 'SoftwareID':
        SoftwareIDValid = value.length >= 10;
        fieldValidationErrors.SoftwareID = SoftwareIDValid ? '': 'Mas de 10 caracteres';
        break;         
      case 'TestSetId':
        TestSetIdValid = value.length >= 10;
        fieldValidationErrors.TestSetId = TestSetIdValid ? '': 'Mas de 10 caracteres';
        break;         
      case 'Municipio':
        MunicipioValid = value.match(/^\d{5}$/);
        fieldValidationErrors.Municipio = MunicipioValid ? '': 'Solo 5 numeros';
        break;
      case 'SupplierAdditionalAccountID':
        SupplierAdditionalAccountIDValid = value.match(/^\d{1}$/);
        fieldValidationErrors.SupplierAdditionalAccountID = SupplierAdditionalAccountIDValid ? '': 'Solo un numeros';
        break;
      case 'SupplierID':
        SupplierIDValid = value.match(/^\d{2}$/);
        fieldValidationErrors.SupplierID = SupplierIDValid ? '': 'Solo dos numeros';
        break;
      case 'SupplierTaxLevelCode':
        SupplierTaxLevelCodeValid = value.match(/^([A-Z0-9;-])+$/);
        fieldValidationErrors.SupplierTaxLevelCode = SupplierTaxLevelCodeValid ? '': 'Solo caracteres autorizados';
        break;
      case 'SupplierTaxLevelCodelistName':
        SupplierTaxLevelCodelistNameValid = value.match(/^\d{2}$/);
        fieldValidationErrors.SupplierTaxLevelCodelistName = SupplierTaxLevelCodelistNameValid ? '': 'Solo dos numeros';
        break;
      case 'Passwordapp':
        PasswordappValid = value.length >= 2;
        fieldValidationErrors.Passwordapp = PasswordappValid ? '': 'Ejemplo: MiPassword';
        break;
      case 'Dolurlapi':
        DolurlapiValid = value.match(/^([A-Za-z0-9 .#/:_-])+$/);
        fieldValidationErrors.Dolurlapi = DolurlapiValid ? '': 'Ejemplo:  https://misubdominio.facturadian.com';
        break;
      case 'Configpath':
        ConfigpathValid = value.match(/^([A-Za-z0-9 .#/:_-])+$/);
        fieldValidationErrors.Configpath = ConfigpathValid ? '': 'Ejemplo:  /var/www/html/erpcrm/htdocs/conf/conf.php';
        break;        
      case 'Tipo':
        TipoValid = value.match(/^([A-Za-z])+$/);
        fieldValidationErrors.Tipo = TipoValid ? '': 'Ejemplo:  Seleccionar';
        break; 
        
      default:
        break;
    }
    this.setState({formErrors: fieldValidationErrors,
                    SupplierNamelValid: SupplierNamelValid,
                    SupplierPartyIdentificationValid: SupplierPartyIdentificationValid,
                    SupplierPartyDVValid: SupplierPartyDVValid,
                    SupplierAddressContactElectronicMailValid: SupplierAddressContactElectronicMailValid,
                    SupplierAddressContactTelephoneValid: SupplierAddressContactTelephoneValid,
                    SupplierLineValid: SupplierLineValid,
                    
                    PinDianValid: PinDianValid,
                    SoftwareIDValid: SoftwareIDValid,
                    TestSetIdValid: TestSetIdValid,
                    MunicipioValid: MunicipioValid,
                   
                    SupplierAdditionalAccountIDValid: SupplierAdditionalAccountIDValid,
                    SupplierIDValid: SupplierIDValid,
                    SupplierTaxLevelCodeValid: SupplierTaxLevelCodeValid,
                    SupplierTaxLevelCodelistNameValid: SupplierTaxLevelCodelistNameValid,
                    
                    PasswordappValid: PasswordappValid,
                    DolurlapiValid: DolurlapiValid,
                    ConfigpathValid: ConfigpathValid,
                    TipoValid: TipoValid
                    
                  }, this.validateForm);
  }

  validateForm() {
    this.setState({ 
      formValid: 
        this.state.SupplierNamelValid && 
        this.state.SupplierPartyIdentificationValid && 
        this.state.SupplierPartyDVValid &&
        this.state.SupplierAddressContactElectronicMailValid &&
        this.state.SupplierAddressContactTelephoneValid &&
        this.state.SupplierLineValid &&

        this.state.PinDianValid &&
        this.state.SoftwareIDValid &&
        this.state.TestSetIdValid &&
        this.state.MunicipioValid &&

        this.state.SupplierAdditionalAccountIDValid &&
        this.state.SupplierIDValid &&
        this.state.SupplierTaxLevelCodeValid &&
        this.state.SupplierTaxLevelCodelistNameValid &&
        
        this.state.PasswordappValid &&
        this.state.DolurlapiValid && 
        this.state.ConfigpathValid &&
        this.state.TipoValid
    });
  }

  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }
  
  onChangeText(key, value) {
    this.setState({
      [key]: value
    });
  }
  
  handleOpenModal () {
    this.setState({ showModal: true });
  }
  
  handleCloseModal () {
    this.setState({ showModal: false });
  }
  
  _leerdatos() {

        const listMunicipios = `
          query list {
            listMunicipios(limit: 1200, nextToken: null) {
              items {
									codigodepartamento
                	nombredepartamento
                	codigomunicipio
                	nombremunicipio
              }
            }
          }
        `;

        const getParametros = `
          query list {
            getParametros(ProviderSub: "` + this.state.sub + `") {
              SupplierName
              SupplierPartyIdentification
              SupplierPartyDV
              SupplierAddressContactElectronicMail
              SupplierAddressContactTelephone
              SupplierLine
              PinDian
              SoftwareID
              TestSetId
              Municipio
              SupplierAddressCityName
              SupplierAddressID
              SupplierAddressCountrySubentity
              SupplierAddressCountrySubentityCode
              SupplierAdditionalAccountID
              SupplierID
              SupplierTaxLevelCode
              SupplierTaxLevelCodelistName
              Passwordapp
              Dolurlapi
              Configpath
              Tipo
            }
          }
        `;
        
        this.handleOpenModal();
        
        API.graphql(graphqlOperation(listMunicipios))
        .then((data) => {
              this.setState({ municipios: data.data.listMunicipios.items });
          })
          .catch(err => {
            this.handleCloseModal();
            if (! err.message) {
              alert('Error 1 municipios: ', err);
            } else {
              alert('Error 2 municipios: ', err.message);
            }
          });        
        
        API.graphql(graphqlOperation(getParametros))
        .then((data) => {
            if (data.data.getParametros === null) {
                this.onChangeText('accion', 'insert');
                //console.log("Modo insertar");
            } else {
              //console.log("Modo editar");
              this.onChangeText('accion', 'edit');
              this.setState({ datos: data.data.getParametros });
              this.onChangeText('SupplierName',   this.state.datos.SupplierName);
              this.onChangeText('SupplierPartyIdentification',   this.state.datos.SupplierPartyIdentification);
              this.onChangeText('SupplierPartyDV',   this.state.datos.SupplierPartyDV);
              this.onChangeText('SupplierAddressContactElectronicMail',   this.state.datos.SupplierAddressContactElectronicMail);
              this.onChangeText('SupplierAddressContactTelephone',   this.state.datos.SupplierAddressContactTelephone);
              this.onChangeText('SupplierLine',   this.state.datos.SupplierLine);
              
              this.onChangeText('PinDian',   this.state.datos.PinDian);
              this.onChangeText('SoftwareID',this.state.datos.SoftwareID);
              this.onChangeText('TestSetId', this.state.datos.TestSetId);
              
              this.onChangeText('Municipio', this.state.datos.Municipio);
              this.onChangeText('SupplierAddressCityName', this.state.datos.SupplierAddressCityName);
              this.onChangeText('SupplierAddressID', this.state.datos.SupplierAddressID);
              this.onChangeText('SupplierAddressCountrySubentity', this.state.datos.SupplierAddressCountrySubentity);
              this.onChangeText('SupplierAddressCountrySubentityCode', this.state.datos.SupplierAddressCountrySubentityCode);
              
              this.onChangeText('SupplierAdditionalAccountID',   this.state.datos.SupplierAdditionalAccountID);
              this.onChangeText('SupplierID',   this.state.datos.SupplierID);
              this.onChangeText('SupplierTaxLevelCode',   this.state.datos.SupplierTaxLevelCode);
              this.onChangeText('SupplierTaxLevelCodelistName',   this.state.datos.SupplierTaxLevelCodelistName);
              
              this.onChangeText('Passwordapp',   this.state.datos.Passwordapp);
              this.onChangeText('Dolurlapi',   this.state.datos.Dolurlapi);
              this.onChangeText('Configpath',   this.state.datos.Configpath);
              this.onChangeText('Tipo',   this.state.datos.Tipo);
              
            }
            this.handleCloseModal();
          })
          .catch(err => {
            this.handleCloseModal();
            if (! err.message) {
              alert('Error 1 getParametros que paso: ', err);
            } else {
              alert('Error 2: ', err.message);
            }
          });

    }
    
  _save = () => {
    
  if(this.state.accion === 'insert') {
    
        const getMunicipios = `
        query get {
            getMunicipios(codigomunicipio:"` + this.state.Municipio + `"){
        									codigodepartamento
                        	nombredepartamento
                        	codigomunicipio
                        	nombremunicipio
            }
        }
        `;
        
        this.handleOpenModal();
        
        API.graphql(graphqlOperation(getMunicipios))
        .then((data) => {
            
            //Guardar
            
                const createParametros = `
                mutation create {
                  createParametros(input: {
                    ProviderSub:  "` + this.state.sub + `"
                    ProviderEmail:  "` + this.state.ProviderEmail + `"
                    SupplierName: "` + this.state.SupplierName + `"
                    SupplierPartyIdentification: "` + this.state.SupplierPartyIdentification + `"
                    SupplierPartyDV: "` + this.state.SupplierPartyDV + `"
                    SupplierAddressContactElectronicMail: "` + this.state.SupplierAddressContactElectronicMail + `"
                    SupplierAddressContactTelephone: "` + this.state.SupplierAddressContactTelephone + `"
                    SupplierLine: "` + this.state.SupplierLine + `"
                    PinDian: "` + this.state.PinDian + `"
                    SoftwareID: "` + this.state.SoftwareID + `"
                    TestSetId: "` + this.state.TestSetId + `"
                    Municipio: "` + this.state.Municipio + `"
                    SupplierAddressID: "` + data.data.getMunicipios.codigomunicipio + `"
                    SupplierAddressCountrySubentity: "` + data.data.getMunicipios.nombredepartamento + `"
                    SupplierAddressCountrySubentityCode: "` + data.data.getMunicipios.codigodepartamento + `"
                    SupplierAdditionalAccountID: "` + this.state.SupplierAdditionalAccountID + `"
                    SupplierAdditionalAccountID: "` + this.state.SupplierAdditionalAccountID + `"
                    SupplierID: "` + this.state.SupplierID + `"
                    SupplierTaxLevelCode: "` + this.state.SupplierTaxLevelCode + `"
                    SupplierTaxLevelCodelistName: "` + this.state.SupplierTaxLevelCodelistName + `"
                    Passwordapp: "` + this.state.Passwordapp + `"
                    Dolurlapi: "` + this.state.Dolurlapi + `"
                    Configpath: "` + this.state.Configpath + `"
                    Tipo: "` + this.state.Tipo + `"
                    }) {
                      ProviderSub
                    }
                  }
              `;
              
              this.handleOpenModal();
              API.graphql(graphqlOperation(createParametros))
              .then(() => {
                  this.handleCloseModal();
                })
              .catch(err => {
                this.handleCloseModal();
                if (! err.message) {
                  alert('Error when signing in: ', err);
                } else {
                  alert('Error when signing in: ', err.message);
                }
              });
            
            //Fin Guardar
    
    

          })
          .catch(err => {
            this.handleCloseModal();
            if (! err.message) {
              alert('Error 1 municipios: ', err);
            } else {
              alert('Error 2 municipios: ', err.message);
            }
          }); 

        
     }

      if(this.state.accion === 'edit') {
        
        const getMunicipios = `
        query get {
            getMunicipios(codigomunicipio:"` + this.state.Municipio + `"){
        									codigodepartamento
                        	nombredepartamento
                        	codigomunicipio
                        	nombremunicipio
            }
        }
        `;

        this.handleOpenModal();
        
        API.graphql(graphqlOperation(getMunicipios))
        .then((data) => {

              //guardar
              const updateParametros = `
                mutation create {
                updateParametros(input: {
                    ProviderSub: "` + this.state.sub + `"
                    ProviderEmail: "` + this.state.ProviderEmail + `"
                    SupplierName: "` + this.state.SupplierName + `"
                    SupplierPartyIdentification: "` + this.state.SupplierPartyIdentification + `"
                    SupplierPartyDV: "` + this.state.SupplierPartyDV + `"
                    SupplierAddressContactElectronicMail: "` + this.state.SupplierAddressContactElectronicMail + `"
                    SupplierAddressContactTelephone: "` + this.state.SupplierAddressContactTelephone + `"
                    SupplierLine: "` + this.state.SupplierLine + `"
                    PinDian: "` + this.state.PinDian + `"
                    SoftwareID: "` + this.state.SoftwareID + `"
                    TestSetId: "` + this.state.TestSetId + `"
                    Municipio: "` + this.state.Municipio + `"
                    SupplierAddressCityName: "` + data.data.getMunicipios.nombremunicipio + `"
                    SupplierAddressID: "` + data.data.getMunicipios.codigomunicipio + `"
                    SupplierAddressCountrySubentity: "` + data.data.getMunicipios.nombredepartamento + `"
                    SupplierAddressCountrySubentityCode: "` + data.data.getMunicipios.codigodepartamento + `"
                    SupplierAdditionalAccountID: "` + this.state.SupplierAdditionalAccountID + `"
                    SupplierID: "` + this.state.SupplierID + `"
                    SupplierTaxLevelCode: "` + this.state.SupplierTaxLevelCode + `"
                    SupplierTaxLevelCodelistName: "` + this.state.SupplierTaxLevelCodelistName + `"
                    Passwordapp: "` + this.state.Passwordapp + `"
                    Dolurlapi: "` + this.state.Dolurlapi + `"
                    Configpath: "` + this.state.Configpath + `"
                    Tipo: "` + this.state.Tipo + `"
                  }) {
                    ProviderSub
                  }
                }
              `;
            
              API.graphql(graphqlOperation(updateParametros))
              .then(() => {
                  this.handleCloseModal();
                  this.onChangeText('redirect', true);
                })
              .catch(err => {
                this.handleCloseModal();
                if (! err.message) {
                  alert('Error when signing in: ', err);
                } else {
                  alert('Error when signing in: ', err.message);
                }
              });
              
              //fin guardar
              
          })
          .catch(err => {
            this.handleCloseModal();
            if (! err.message) {
              alert('Error 1 municipios: ', err);
            } else {
              alert('Error 2 municipios: ', err.message);
            }
          }); 

        

     }
  }

  render () {
    
      const { redirect } = this.state;
      if (redirect) {
         return <Redirect push to="/Servicio/"/>;
      }
       
    return (
      
      <div style={{ padding: 40 }} class="spinner-border">
      
          <ReactModal isOpen={this.state.showModal} >
              <img src={require('./loading.gif')} alt="loading..." />
          </ReactModal>
          
          <Typography variant="h5" gutterBottom style={{ marginTop: 20, color: "blue" }}>
               Datos de mi Empresa
          </Typography>
          
          <br />
          <Typography variant="h7" gutterBottom style={{ marginTop: 20 }}>
            Ahora vamos a ingresar los datos de nuestra empresa, es muy simple, cada campo 
            del formulario esta validado no tendras pierde. <br /><br />
            El boton de guardar al final del formulario se activara solo cuando recorras todos 
            los campos solicitados. Si requieres actualizar algun datos posteriormente, recorre 
            todos los campos desde el principio con la tecla TAB y modificar el dato cuando pases 
            por el campo.
            <br /><br />
            Al guardar o actualizar te enviara automaticamente a <b>"Desplegar-ERP/CRM"</b>, alli podras 
            lanzar tu servicio de ERP/CRM, asi podras crear tus facturas.
          </Typography>
          
          <div className="panel panel-default">
            <FormErrors formErrors={this.state.formErrors} />
          </div>
          
          <Typography variant="h4" gutterBottom style={{ marginTop: 20, color: "blue" }}>
            Datos basicos
          </Typography>
        
          <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.SupplierName)}`}>
                  <label htmlFor="SupplierName">Nombre de la Empresa (Solo se aceptan: <b>A-Za-z0-9 ._-</b> )</label>
                  <input type="text" required className="form-control" name="SupplierName"
                    placeholder="Solo se aceptan: A-Za-z0-9 ._-"
                    value={this.state.SupplierName}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.SupplierPartyIdentification)}`}>
                  <label htmlFor="SupplierPartyIdentification">Nit de la empresa (<b>Sin puntos ni guiones ni espacios</b>)</label>
                  <input type="text" className="form-control" name="SupplierPartyIdentification"
                    placeholder="Sin puntos ni guiones ni espacios"
                    value={this.state.SupplierPartyIdentification}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.SupplierPartyDV)}`}>
                  <label htmlFor="SupplierPartyDV">Digito de verificacion (<b>Solo un numero</b>)</label>
                  <input type="text" className="form-control" name="SupplierPartyDV"
                    placeholder="Solo un numero"
                    value={this.state.SupplierPartyDV}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>      
              <Grid item xs={12}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.SupplierAddressContactElectronicMail)}`}>
                  <label htmlFor="SupplierAddressContactElectronicMail">Correo o email (<b>Un correo valido</b>)</label>
                  <input type="text" className="form-control" name="SupplierAddressContactElectronicMail"
                    placeholder="Un correo valido"
                    value={this.state.SupplierAddressContactElectronicMail}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>              
              <Grid item xs={12}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.SupplierAddressContactTelephone)}`}>
                  <label htmlFor="SupplierAddressContactTelephone">Un Telefono (Solo se aceptan: <b>0-9()+</b>)</label>
                  <input type="text" className="form-control" name="SupplierAddressContactTelephone"
                    placeholder="Solo se aceptan: 0-9()+"
                    value={this.state.SupplierAddressContactTelephone}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid> 
              <Grid item xs={12}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.SupplierLine)}`}>
                  <label htmlFor="SupplierLine">Direccion de la empresa (Solo se aceptan: <b>A-Za-z0-9 .#_-</b>)</label>
                  <input type="text" className="form-control" name="SupplierLine"
                    placeholder="Solo se aceptan: A-Za-z0-9 .#_-"
                    value={this.state.SupplierLine}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>
            </Grid>  
              
          <Typography variant="h4" gutterBottom style={{ marginTop: 20, color: "blue" }}>
            Datos Dian
          </Typography>
        
          <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.PinDian)}`}>
                  <label htmlFor="PinDian">Pin Dian (<b>Obtenido en el PASO 1</b>)</label>
                  <input type="text" required className="form-control" name="PinDian"
                    placeholder="Solo numeros"
                    value={this.state.PinDian}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.SoftwareID)}`}>
                  <label htmlFor="SoftwareID">SoftwareID (<b>Obtenido en el PASO 1</b>)</label>
                  <input type="text" className="form-control" name="SoftwareID"
                    placeholder="Minimo 10 caracteres alfanumericos"
                    value={this.state.SoftwareID}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.TestSetId)}`}>
                  <label htmlFor="TestSetId">TestSetId (<b>Obtenido en el PASO 1</b>)</label>
                  <input type="text" className="form-control" name="TestSetId"
                    placeholder="Minimo 10 caracteres  alfanumericos"
                    value={this.state.TestSetId}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>
              
              <Grid item xs={12}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.Municipio)}`}>
                  <label htmlFor="Municipio">Depatamento / municipio</label>
                  <select className="form-control" name="Municipio" 
                    value={this.state.Municipio} 
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}>
                    {this.state.municipios.map((row) => (
                        <option value={row.codigomunicipio}>{row.nombremunicipio}({row.codigomunicipio}) {row.nombredepartamento}({row.codigodepartamento})</option>
                    ))}
                  </select>
                </div>
              </Grid>

            </Grid>
            
          <Typography variant="h4" gutterBottom style={{ marginTop: 20, color: "blue" }}>
            Datos Fiscales
          </Typography>              

            <Grid container spacing={3}>  
              <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.SupplierAdditionalAccountID)}`}>
                  <label htmlFor="SupplierAdditionalAccountID">Tipo persona</label>
                  <select className="form-control" name="SupplierAdditionalAccountID" 
                    value={this.state.SupplierAdditionalAccountID} 
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}>
                    <option value="1">(1) Persona Juridica</option>
                    <option value="2">(2) Persona natural</option>
                  </select>
                </div>
              </Grid>              
              <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.SupplierID)}`}>
                  <label htmlFor="SupplierID">Tipo identificacion</label>
                  <select className="form-control" name="SupplierID" 
                    value={this.state.SupplierID} 
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}>
                    <option value="31">(31) Nit</option>
                    <option value="13">(13) Cedula</option>
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.SupplierTaxLevelCodelistName)}`}>
                  <label htmlFor="SupplierTaxLevelCodelistName">Responsable del Iva</label>
                  <select className="form-control" name="SupplierTaxLevelCodelistName" 
                    value={this.state.SupplierTaxLevelCodelistName} 
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}>
                    <option value="48">(48) Si</option>
                    <option value="49">(49) No</option>
                  </select>
                </div>
              </Grid> 
              <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.SupplierTaxLevelCode)}`}>
                  <label htmlFor="SupplierTaxLevelCode">Responsabilidades fiscales</label>
                  <input type="text" className="form-control" name="SupplierTaxLevelCode"
                    placeholder="Solo caracteres autorizados"
                    value={this.state.SupplierTaxLevelCode}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>              
            </Grid>  

          <Typography variant="h4" gutterBottom style={{ marginTop: 20, color: "blue" }}>
            Datos ERP/CRM
          </Typography> 

            <Grid container spacing={3}>  
              <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.Passwordapp)}`}>
                  <label htmlFor="Passwordapp">Password de la Apps (<b>Coloque NA si no la Conoce</b>)</label>
                  <input type="text" className="form-control" name="Passwordapp"
                    placeholder="Ej: gjEptsonDVg9FVtWZQLqth38s31tRs54"
                    value={this.state.Passwordapp}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.Dolurlapi)}`}>
                  <label htmlFor="Dolurlapi">UrlApi - Su dominio de ERP/CRM - No coloque el ultimo / (<b>Coloque NA si no la Conoce</b>)</label>
                  <input type="text" className="form-control" name="Dolurlapi"
                    placeholder="Ej: https://misubdominio.facturadian.com"
                    value={this.state.Dolurlapi}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>
                <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.Configpath)}`}>
                  <label htmlFor="Configpath">Configpath de ERP/CRM (<b>Coloque NA si no la Conoce</b>)</label>
                  <input type="text" className="form-control" name="Configpath"
                    placeholder="Ej:  /var/www/html/erpcrm/htdocs/conf/conf.php"
                    value={this.state.Configpath}
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={`form-group ${this.errorClass(this.state.formErrors.Tipo)}`}>
                  <label htmlFor="Tipo">Tipo de despliegue</label>
                  <select className="form-control" name="Tipo" 
                    value={this.state.Tipo} 
                    onFocus={this.handleUserInput}
                    onChange={this.handleUserInput}>
                    <option value="Estandar">Estandar</option>
                    <option value="Comercio">Comercio</option>
                    <option value="Servicio">Servicio</option>
                    <option value="Pos">Pos</option>
                    <option value="Manufactura">Manufactura</option>
                    <option value="Certificados">Certificados</option>
                    <option value="ph">Propiedad horizontal</option>
                  </select>
                </div>
              </Grid>
            </Grid>  

            <button type="submit" className="btn btn-primary" disabled={!this.state.formValid} onClick={this._save}>Guardar / Actualizar</button>          
            <Typography variant="h4" gutterBottom style={{ marginTop: 20, color: "blue" }}>
            Para activar este Boton, repasa todos los campos primero usando la Tecla TAB
          </Typography>
      </div>
    );
  }
}

export default Certificado;

import React from 'react';
import {Typography,Button} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ViewIcon from '@material-ui/icons/More';
import { Redirect } from 'react-router';

import axios from 'axios';
import ReactModal from 'react-modal';
import Auth from '@aws-amplify/auth';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class Inicio extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
            email: String, 
            sub: String,
            datos: [],
            ambiente: "1",
            showModal: false,
    };
    
  }
  
  onChangeText(key, value) {
    this.setState({
      [key]: value
    });
  }
  
  async componentDidMount() {
      
  Auth.currentAuthenticatedUser()
    .then(user => {
      this.onChangeText('email', user.attributes.email);
      this.onChangeText('sub', user.attributes.sub);
      this._leerdatos();
    })
    .catch(err => console.log(err));
  }
  
  handleOpenModal () {
    this.setState({ showModal: true });
  }
  
  handleCloseModal () {
    this.setState({ showModal: false });
  }
  
  _leerdatos() {
          var today = new Date();
          var year = today.getFullYear();
          this.handleOpenModal();
          let data = {
            Ambiente: this.state.ambiente,
            ProviderEmail: this.state.email,
            ProviderSub: this.state.sub,
            year: year.toString()
          };
      console.log(data);
      let url = "https://2jr7q76fbi.execute-api.us-east-1.amazonaws.com/prod/alertas";
      axios.post(url, data)
        .then(response => {
          this.handleCloseModal();
          console.log(response.data);
          this.onChangeText('datos', response.data);
          //alert("Servicio Creado, recibira un Email con los datos de acceso");
        })
        .catch(error => {
          this.handleCloseModal();
          alert("Error de comunicacion con el servidor...");
        });
    }
  
  
  _refrescar = () => {
    this._leerdatos();
  }
  
  
  _view = async (invoice) => {

  }
  

  render() {
    
    const redirectEdit = this.state.redirectEdit;
    if (redirectEdit) {
      
      return <Redirect push to={{
                pathname: '/EditPrefijo',
                state: { prefijo: this.state.prefijoEditar }
              }}/>;
      
       //return <Redirect push to="/EditPrefijo/"/>;
    }
    
    const prefijoBorrar = this.state.prefijoBorrar;
    
    return (

    <div style={{ padding: 40 }}>

      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Seguro quiere Borrar el prefijo "+prefijoBorrar+" ?" }</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              Si lo borra debera retirarlo de Dolibarr tambien...
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary" autoFocus>
            Cancelar
          </Button>
          <Button onClick={() => this._yesDeletePrefijo(prefijoBorrar)} color="primary">
            Borrarlo
          </Button>
        </DialogActions>
      </Dialog>

      
      
      <ReactModal isOpen={this.state.showModal} >
          <img src={require('./loading.gif')} alt="loading..." />
      </ReactModal>
      
      <h3 style={{ marginTop: 25}}><center><font color='blue'>Alertas de documentos (no enviados/no validos) en Dian o no entregados a Clientes</font></center></h3>

      <button type="submit" className="btn btn-primary" onClick={this._refrescar}>Actualizar datos</button>

      <Typography >
        Aqui se registran todos los documento que  este momento no fueron entregados o validados ante la Dian 
        y los documentos que no fueron entregados a los clientes. No incluye los documentos repudiados por 
        los clientes ya que estos siguen una tramite diferente.
      </Typography>      

      <TableContainer style={{ marginTop: 20}} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow style={{ background: "grey" }}>
            <TableCell align="center">Item&nbsp;</TableCell>
            <TableCell align="center">Invoice&nbsp;</TableCell>
            <TableCell align="center">IssueDate&nbsp;</TableCell>
            <TableCell align="center">Doc&nbsp;</TableCell>
            <TableCell align="center">ValorBase&nbsp;</TableCell>
            <TableCell align="center">%Iva&nbsp;</TableCell>
            <TableCell align="center">ReceptorName&nbsp;</TableCell>
            <TableCell align="center">ReceptorId&nbsp;</TableCell>
            <TableCell align="center">IsValid&nbsp;</TableCell>
            <TableCell align="center">Cliente&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.datos.map((row) => (
            <TableRow key={row.name}>
              <TableCell align="left">{row.consecutivo}</TableCell>
              <TableCell component="th" scope="row">
                <IconButton size="small" color="primary" onClick={() => this._view(row.Invoice)}>
                  <ViewIcon />{row.Invoice}
                </IconButton>
              </TableCell>
              <TableCell align="left">{row.IssueDate}</TableCell>
              <TableCell align="left">{row.DocumentTypeCode}</TableCell>
              <TableCell align="right">
                {new Intl.NumberFormat("es-ES").format(row.LineExtensionAmount)}
              </TableCell>
              <TableCell align="left">{row.Percent1}</TableCell>
              <TableCell align="left">{row.ReceptorName}</TableCell>
              <TableCell align="left">{row.ReceptorId}</TableCell>
              <TableCell align="left">{row.IsValid}</TableCell>
              <TableCell align="left">{row.evento}</TableCell>
            </TableRow>
          ))}
          
        </TableBody>
      </Table>
    </TableContainer>

  </div>
      
    );
  }
}


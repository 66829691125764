import React from 'react';
import {Typography,Button} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { Redirect } from 'react-router';

import ReactModal from 'react-modal';
import { API, graphqlOperation } from 'aws-amplify';
import Auth from '@aws-amplify/auth';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class Inicio extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      redirectAdd: false,
      redirectEdit: false,
      showModal: false,
      accion: String,
      username: String,
      sub: String,
      ProviderEmail: String,
      datos: [],
      open: false, 
      prefijoBorrar: String,
      prefijoEditar: String
    };
    
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  
  onChangeText(key, value) {
    this.setState({
      [key]: value
    });
  }
  
  async componentDidMount() {
      
  Auth.currentAuthenticatedUser()
    .then(user => {
      this.onChangeText('username', user.username);
      this.onChangeText('sub', user.attributes.sub);
      this.onChangeText('ProviderEmail', user.attributes.email);
      this._leerdatos();
    })
    .catch(err => console.log(err));
  }
  
  _leerdatos() {
      
        const listPrefijos = `
          query list {
            listPrefijos(limit: 2000, nextToken: null filter: {
              ProviderSub: {
                contains: "` + this.state.sub + `"
              }
            }) {
              items {
                  Prefijo
                  invoiceAuthorization
                  desde
                  hasta
                  startDate
                  endDate
                  emailDespacho
                  emailCopia
                  Ambiente
                  ClaveTecnica
              }
            }
          }
        `;
        
        this.handleOpenModal();
        API.graphql(graphqlOperation(listPrefijos))
        .then((data) => {
            this.setState({ datos: data.data.listPrefijos.items });
            this.handleCloseModal();
            console.log(this.state.datos);
          })
          .catch(err => {
            this.handleCloseModal();
            if (! err.message) {
              alert('Error 1: ', err);
            } else {
              alert('Error 2: ', err.message);
            }
          });
    }
  
  
  _add = async () => {
      this.onChangeText('redirectAdd', true);
  }

  _edit = async (prefijo) => {
      this.onChangeText("prefijoEditar", prefijo);
      this.onChangeText('redirectEdit', true);
  }
  
   _delete = async (prefijo) => {
      this.onChangeText("prefijoBorrar", prefijo);
      this.handleClickOpen();
  }
  
  _yesDeletePrefijo = async (prefijo) => {
    this.handleClose();
    
    const deletePrefijos = `
      mutation delete {
            deletePrefijos(input: {
               ProviderSub: "` + this.state.sub + `"
               Prefijo: "` + prefijo + `"
           }) {
             Prefijo
           }
          }
        `;

      //console.log(deletePrefijos);
    
      this.handleOpenModal();
      await API.graphql(graphqlOperation(deletePrefijos))
      .then(() => {
          this.handleCloseModal();
          this._leerdatos();
        })
      .catch(err => {
        this.handleCloseModal();
        if (! err.message) {
          console.log('Error when signing in: ', err);
        } else {
          console.log('Error when signing in: ', err.message);
        }
      });

  }
  
  handleOpenModal () {
    this.setState({ showModal: true });
  }
  handleCloseModal () {
    this.setState({ showModal: false });
  }
  
  handleClickOpen () {
     this.setState({ open: true });
  }
  handleClose () {
    this.setState({ open: false });
  }
  

  render() {
    
    
    const redirectAdd = this.state.redirectAdd;
    if (redirectAdd) {
       return <Redirect push to="/NewPrefijo/"/>;
    }
    const redirectEdit = this.state.redirectEdit;
    if (redirectEdit) {
      
      return <Redirect push to={{
                pathname: '/EditPrefijo',
                state: { prefijo: this.state.prefijoEditar }
              }}/>;
      
       //return <Redirect push to="/EditPrefijo/"/>;
    }
    
    const prefijoBorrar = this.state.prefijoBorrar;
    
    return (

    <div style={{ padding: 40 }}>

      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Seguro quiere Borrar el prefijo "+prefijoBorrar+" ?" }</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              Si lo borra debera retirarlo de Dolibarr tambien...
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary" autoFocus>
            Cancelar
          </Button>
          <Button onClick={() => this._yesDeletePrefijo(prefijoBorrar)} color="primary">
            Borrarlo
          </Button>
        </DialogActions>
      </Dialog>

      
      
      <ReactModal isOpen={this.state.showModal} >
          <img src={require('./loading.gif')} alt="loading..." />
      </ReactModal>
          
      <Typography variant="h5" gutterBottom style={{ marginTop: 20, color: "blue" }}>
        Mis prefijos
      </Typography>

      <Typography >
        Aqui se ubican todos los prefijos tanto los de habilitacion como los de produccion. Recordemos que los 
        prefijos de habilitacion (SETP, NCX y NDX) se cargan automaticamente cuando se activa el servicio y 
        son usados para aprobar tu cuenta de FacturaDian ente la DIAN como facturador directo.
        <br /><br />
        Los prefijos para produccion se adicionaran automaticamente en el <b>PASO 7 - Rangos de numeracion DIAN</b>
      </Typography>      
      
      <div style={{ marginTop: 20}}>
        <Button variant="contained" color="default" startIcon={<AddIcon />} onClick={() => this._add()} > Nuevo prefijo </Button>
      </div>

      <TableContainer style={{ marginTop: 20}} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow style={{ background: "grey" }}>
            <TableCell align="center">Prefijo&nbsp;</TableCell>
            <TableCell align="center">Borrar&nbsp;</TableCell>
            <TableCell align="center">Ambiente&nbsp;</TableCell>
            <TableCell align="center">ClaveTecnica&nbsp;</TableCell>
            <TableCell align="center">Authorization&nbsp;</TableCell>
            <TableCell align="center">Desde&nbsp;</TableCell>
            <TableCell align="center">Hasta&nbsp;</TableCell>
            <TableCell align="center">ValidoDesde&nbsp;</TableCell>
            <TableCell align="center">ValidoHasta&nbsp;</TableCell>
            <TableCell align="center">EmailDespacho&nbsp;</TableCell>
            <TableCell align="center">EmailCopia&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.datos.map((row) => (
            <TableRow key={row.name}>
            
              <TableCell component="th" scope="row">
                <IconButton size="small" color="primary" onClick={() => this._edit(row.Prefijo)}>
                  <EditIcon />{row.Prefijo}
                </IconButton>
              </TableCell>

              <TableCell component="th" scope="row">
                <IconButton size="small" color="primary" onClick={() => this._delete(row.Prefijo)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center">{row.Ambiente}</TableCell>
              <TableCell align="left">{row.ClaveTecnica}</TableCell>
              <TableCell align="left">{row.invoiceAuthorization}</TableCell>
              <TableCell align="left">{row.desde}</TableCell>
              <TableCell align="left">{row.hasta}</TableCell>
              <TableCell align="left">{row.startDate}</TableCell>
              <TableCell align="left">{row.endDate}</TableCell>
              <TableCell align="left">{row.emailDespacho}</TableCell>
              <TableCell align="left">{row.emailCopia}</TableCell>
            </TableRow>
          ))}
          
        </TableBody>
      </Table>
    </TableContainer>

  </div>
      
    );
  }
}


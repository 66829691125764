// Cognito, dynamodb y amplify estan en la cuenta aws de factracion electronica eclipse2p@hotmail.com
const awsmobile = {
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_R4WOlQpUM",
    "aws_user_pools_web_client_id": "17rgho1jh91voutrfjph918v24",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://m6pnfulesvgprlzh5q5kxracou.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};

export default awsmobile;

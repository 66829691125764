import React from 'react';
import '../App.css';

import Auth from '@aws-amplify/auth';
import { Typography, Avatar} from '@material-ui/core';
import { ReceiptCutoff } from 'react-bootstrap-icons';

import { Link } from "react-router-dom";

import SetDePruebasFoto1 from './fotos/SetDePruebasFoto1.png';
import SetDePruebasFoto2 from './fotos/SetDePruebasFoto2.png';
import SetDePruebasFoto3 from './fotos/SetDePruebasFoto3.png';
import SetDePruebasFoto4 from './fotos/SetDePruebasFoto4.png';
import SetDePruebasFoto5 from './fotos/SetDePruebasFoto5.png';
import SetDePruebasFoto6 from './fotos/SetDePruebasFoto6.png';
import SetDePruebasFoto7 from './fotos/SetDePruebasFoto7.png';
import SetDePruebasFoto8 from './fotos/SetDePruebasFoto8.png';

import EmpresaFoto1 from './fotos/EmpresaFoto1.png';
import EmpresaFoto2 from './fotos/EmpresaFoto2.png';
import EmpresaFoto3 from './fotos/EmpresaFoto3.png';
import EmpresaFoto4 from './fotos/EmpresaFoto4.png';
import EmpresaFoto5 from './fotos/EmpresaFoto5.png';

import CertificadoFoto1 from './fotos/CertificadoFoto1.png';
import CertificadoFoto2 from './fotos/CertificadoFoto2.png';
import CertificadoFoto3 from './fotos/CertificadoFoto3.png';
import CertificadoFoto4 from './fotos/CertificadoFoto4.png';
import CertificadoFoto5 from './fotos/CertificadoFoto5.png';
import CertificadoFoto6 from './fotos/CertificadoFoto6.png';
import CertificadoFoto7 from './fotos/CertificadoFoto7.png';
import CertificadoFoto8 from './fotos/CertificadoFoto8.png';

import ValidarSoftwareFoto1 from './fotos/ValidarSoftwareFoto1.png';
import ValidarSoftwareFoto1a from './fotos/ValidarSoftwareFoto1a.png';
import ValidarSoftwareFoto2 from './fotos/ValidarSoftwareFoto2.png';
import ValidarSoftwareFoto3 from './fotos/ValidarSoftwareFoto3.png';
import ValidarSoftwareFoto4 from './fotos/ValidarSoftwareFoto4.png';
import ValidarSoftwareFoto5 from './fotos/ValidarSoftwareFoto5.png';
import ValidarSoftwareFoto6 from './fotos/ValidarSoftwareFoto6.png';
import ValidarSoftwareFoto8 from './fotos/ValidarSoftwareFoto8.png';
import ValidarSoftwareFoto9 from './fotos/ValidarSoftwareFoto9.png';
import ValidarSoftwareFoto10 from './fotos/ValidarSoftwareFoto10.png';
import ValidarSoftwareFoto11 from './fotos/ValidarSoftwareFoto11.png';
import ValidarSoftwareFoto12 from './fotos/ValidarSoftwareFoto12.png';

import RangosFoto1 from './fotos/RangosFoto1.png';

import ProduccionFoto1 from './fotos/ProduccionFoto1.png';
import ProduccionFoto2 from './fotos/ProduccionFoto2.png';
import ProduccionFoto3 from './fotos/ProduccionFoto3.png';
import ProduccionFoto5 from './fotos/ProduccionFoto5.png';

export default class Inicio extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: String, username: String };
  }
  
  onChangeText(key, value) {
    this.setState({
      [key]: value
    });
  }
  
  async componentDidMount() {
      
  Auth.currentAuthenticatedUser()
    .then(user => {
      this.onChangeText('email', user.attributes.email);
    })
    .catch(err => console.log(err));
  }
  
  render() {
    return (
      
<div>

<br /><br />
<div class="card text-white bg-success mb-3">
  <div class="card-header">
    FacturaDian -  Una solucion de Facturacion electronica Directa.
    <br />
    <a href='https://facturadian-descargas.s3.us-east-2.amazonaws.com/dolibarr_11.0.2/index.html' target='_blank' rel='noopener noreferrer'><b><font color='purple'>INSTRUCCIONES PARA LA INSTALACION DEL MODULO FACTURADIAN EN TU DOLIBARR LOCAL O DE NUBE </font></b></a>
  </div>
  <div class="card-body">
    <h5 class="card-title"><ReceiptCutoff /> GUIA PASO A PASO PARA CONFIGURAR LA DIAN Y TU DOLIBARR</h5>
    <p class="card-text" style={{ fontSize: 12 }}>Aqui encontraras en detalle, todo el proceso para implementar Facturacion Electronica Directa en tu Dolibarr  
    usando el Modulo FacturaDian. Independientemente si eliges tu solucion en nuestra nube cloud o usas tu propio Dolibarr,  
    deberas seguir estos pasos para aprobar ante la DIAN el envio directo de documentos electronicos.</p>
  </div>
</div>
<br />

<div id="accordion">

  <div class="card">
    <div class="card-header" id="headingOne">
      <h5 class="mb-0">
        <button class="btn btn-link collapsedk" data-toggle="collapse" data-target="#paso1" aria-expanded="false" aria-controls="paso1">
          PASO 1 - Creando un set de pruebas
        </button>
      </h5>
    </div>
    <div id="paso1" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">
        <br />
        <Avatar style={{ backgroundColor: "green" }}>1</Avatar>
        <Typography>
            El Set de pruebas de la DIAN es el primer paso que debemos realizar, este consiste en registrarnos en 
            la url <a href="https://catalogo-vpfe-hab.dian.gov.co/User/Login" target="_blank" rel="noopener noreferrer">catalogo-vpfe-hab.dian.gov.co/User/Login</a>, 
            este es uno de los tres portales DIAN que usaremos a lo largo de esta guia.
        </Typography>
        <br />
        <img src={SetDePruebasFoto2} width="400" height="300" alt='foto' />
        <br /><br />
          <Typography>
            Igualmente podemos llegar a este portal desde la pagina web principal de la Dian, por la seccion facturacion electronica y 
            de alli seleccionamos Habilitacion.
        </Typography>
        <br />
        <img src={SetDePruebasFoto1} width="400" height="200" alt='foto' />
        <br /><br />
        <Avatar style={{ backgroundColor: "green" }}>2</Avatar>
        <Typography >
            La dian te enviara un email al correo que tengas registrado en la camara de comercio
        </Typography>
        <br />
        <img src={SetDePruebasFoto3} width="350" height="250" alt='foto'/>
        
        
        <br /><br /><br />
        <Avatar style={{ backgroundColor: "green" }}>3</Avatar>
        <Typography >
            En el correo que recibiste encontraras un link para ingresar al portal de Habilitacion de la Dian. 
            Este acceso o token durara una hora y tendras que volver al paso 2 si requieres ingresar de nuevo.
        </Typography>
        <br />
        <img src={SetDePruebasFoto4} width="400" height="350" alt='foto'/>
        
        
        <br /><br /><br />
        <Avatar style={{ backgroundColor: "green" }}>4</Avatar>
        <Typography >
            Ahora por la Izquierda ingresa por Participantes y luego facturador. 
            y da click en el boton <font color='blue'>configurar modo de operacion </font> 
        </Typography>
        <br />
        <img src={SetDePruebasFoto5} width="400" height="270" alt='foto' />
        
        
        <br /><br /><br />
        <Avatar style={{ backgroundColor: "green" }}>5</Avatar>
        <Typography >
            En modo de operacion selecciona <font color='blue'>Software Propio</font> 
            <br />En <font color='blue'>Nombre del software</font>  ingresa el nombre que desees, eje: MiSoftware
            <br />En <font color='blue'>Pin del SW</font>  ingresa un numero de 5 digitos, eje: 12345
        </Typography>
        <br />
        <img src={SetDePruebasFoto6} width="400" height="270" alt='foto'/>
        
        
        <br /><br /><br />
            <Avatar style={{ backgroundColor: "green" }}>6</Avatar>
            <Typography >
                Ahora en la parte inferior veras una linea con tu software propio,
                Ingresa por <font color='blue'>Detalles del set de pruebas</font>
            </Typography>
        <br />
        <img src={SetDePruebasFoto7} width="400" height="270" alt='foto' />
        
        
        <br /><br /><br />
            <Avatar style={{ backgroundColor: "green" }}>7</Avatar>
            <Typography >
                En esta ultima parte encontraras los 3 datos que requerimos en nuestro proximo <b>PASO 2 - Registrar los datos de mi Empresa</b>.
                <br /><br />Al inicio encontraras el <font color='blue'>Set de pruebas  (TestSetId)</font>
                <br />En la seccion Informacion del Software encontraras la <font color='blue'>Identificacion (SoftwareID)</font> y el <font color='blue'>Pin (PinDian)</font>
            </Typography>
        <br />
        <img src={SetDePruebasFoto8} width="400" height="270" alt='foto'/>

        <br /><br />
            <Typography >
                Ahora que cuentas con los 3 datos que requerimos de la DIAN, iremos al Paso 2 "<b>Registrar los datos de mi Empresa</b>".
            </Typography>
        <br />

      </div>
    </div>
  </div>
  
  <div class="card">
    <div class="card-header" id="headingTwo">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#paso2" aria-expanded="false" aria-controls="paso2">
          PASO 2 - Registrar los datos de mi Empresa
        </button>
      </h5>
    </div>
    <div id="paso2" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">
            Ahora vamos a ingresar los datos de nuestra empresa, es muy simple, igresa por la 
            opcion <b>Datos Empresa</b> del menu principal, cada campo del formulario esta validado 
            no tendras pierde, pero antes de ingresar al formulario de los datos de Empresa, 
            configura tu API REST en tu dolibar asi:
            <br /><br />
            <Avatar style={{ backgroundColor: "orange" }}>1</Avatar>
            Ingresa a los modulos de Dolibarr y activa el modulo <b>API / servicios web (servidor REST) </b>
            <br /><br />
            <img src={EmpresaFoto1} width="500" height="100" alt='foto' />
            <br /><br />
            <Avatar style={{ backgroundColor: "orange" }}>2</Avatar>
            Ingresa a los parametros del modulo y Habilitelo en modo de produccion y copia la URL de la API 
            (es la cadena marcada en rojo y va desde la htt.... hasta antes del /api) en este ejemplo es 
            la cadena <b>http://infofacturadiancom.facturadian.com</b> la necesitaras para ingresar en datos de la Empresa.
            <br /><br />
            <img src={EmpresaFoto2} width="500" height="300" alt='foto' />        
            <br /><br />
            <Avatar style={{ backgroundColor: "orange" }}>3</Avatar>
            Ingresa a Usuarios y Grupos de dolibarr y selecciona a tu SuperAdmin
            <br /><br />
            <img src={EmpresaFoto3} width="500" height="150" alt='foto' />           
            <br /><br />
            <Avatar style={{ backgroundColor: "orange" }}>4</Avatar>
            Modifica el usuario y genera una nueva Clave para API, copia esta clave que necesitaras para ingresa 
            en datos de la Empresa.
            <br /><br />
            <img src={EmpresaFoto4} width="500" height="150" alt='foto' />  
            <br /><br />
            <Avatar style={{ backgroundColor: "orange" }}>5</Avatar>
            Ingresa por Herramientas de Administracon de Dolibarr y selecciona <b>Sobre Dolibarr</b>, alli 
            ubica el parametro <b>Directorio raiz del servidor web</b>, copia este valor agregandole despues 
            <b> /conf/conf.php </b>, este ejemplo quedaria <b>/var/www/html/dolibarr/htdocs/conf/conf.php</b> 
            , toda la cadena la necesitaras para ingresa en datos de la Empresa.
            <br /><br />
            <img src={EmpresaFoto5} width="500" height="150" alt='foto' />              
        
            <br /><br />
            Ahora si ingresa los datos de tu empresa usando la opcion <Link to="/Empresa/">Datos-Empresa</Link> del menu principal.
            <br /><br />
            Al guardar o actualizar te enviara automaticamente al <b>PASO 3 - Desplegando mi Dolibarr"</b>, alli podras 
            lanzar tu servicio de Dolibarr-Facturacion y crear tus facturas, notas debito y credito.
      </div>
    </div>
  </div>
  
  <div class="card">
    <div class="card-header" id="headingThree">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#paso3" aria-expanded="false" aria-controls="paso3">
            PASO 3 - Desplegando mi Dolibarr
        </button>
      </h5>
    </div>
    <div id="paso3" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
            Este paso es muy sencillo y consiste en desplegar tu propio Dolibarr-Facturacion con pulsar un Boton. 
            El boton solo estara activo si ya ingresaste los datos de la Empresa (<b>PASO 2</b>) y No has desplegado tu 
            Dolibarr-Facturacion aun. 
            <br /><br />
            El email con los datos de acceso y las credenciales se enviaran al email que colocaste al abrir la 
            cuenta en <b>portal.facturadian.com</b> (No se enviaran al email que registraste en los datos de la empresa). 
            <br /><br />
            Tambien recibiras un segundo email de AWS, acepta  enviar los email a los clientes tanto en modo pruebas 
            como en produccion dando clic sobre un gran texto azul que te aparece en el centro del correo.
            <br /><br />
            Despliega tu Dolibarr usando la opcion <Link to="/servicio/">Desplegar-Dolibarr</Link> del menu principal.
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-header" id="headingThree">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#paso4" aria-expanded="false" aria-controls="paso4">
          PASO 4 - Prefijos de habilitacion
        </button>
      </h5>
    </div>
    <div id="paso4" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
        Aqui se ubican todos los prefijos tanto los de habilitacion como los de produccion. Recordemos que los prefijos 
        de habilitacion (SETP, NCX y NDX) se cargan automaticamente cuando se activa el servicio y son usados para 
        aprobar tu cuenta de FacturaDian ente la DIAN como facturador directo.
        <br /><br />
        Los prefijos para produccion se adicionaran automaticamente en el <b>PASO 7 - Prefijos en produccion</b>.
        <br /><br />
        Verifica que los prefijos de  habilitacion (SETP, NCX y NDX)  se haya cargado automaticamente en la opcion <Link to="/Prefijos/">Prefijos</Link> del menu principal.
      </div>
    </div>
  </div>
  
  <div class="card">
    <div class="card-header" id="headingThree">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#paso5" aria-expanded="false" aria-controls="paso5">
          PASO 5 - Certificado de firma electronica
        </button>
      </h5>
    </div>
    <div id="paso5" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
        Quizas este sea el paso mas complejo, pero no te preocupes, te detallaremos lo mas posible el 
        procedimiento para que lleves a feliz término este paso. El certificado para firma digital es un archivo 
        llamado certificado.pfx que es emitido en colombia por una de las empresas autorizadas ante la 
        <a href='https://onac.org.co/'> ONAC</a>. Para conocer las entidades o empresas que actualmente estan 
        autorizadas en certificacion Digital (ECD), puedes consultar mas especificamente <a href='https://onac.org.co/directorio-de-acreditacion-buscador'> AQUI</a>.
        <br /><br />
        <img src={CertificadoFoto1} width="500" height="600" alt='foto' />
        <br /><br />
        Nuestros clientes por costos han adquirido sus certificados en <a href='https://thomas-signe.co'>thomas-signe.co</a> y 
        en <a href='https://www.andesscd.com.co'>www.andesscd.com.co</a>.
        <br /><br />
        Para empezar, comunicate con ellos a los telefonos que aparecen en las respectivas paginas web y al comunicarte con un 
        vendedor, le comentaras que requieres un <b>Certificado de firma digital para Facturacion Electronica DIAN</b>, ellos te 
        enviaran una cotizacion entre $119.000 y $250.000 aproximadamente, estas entidades son las mas economicas.
        <br /><br />
        <Avatar style={{ backgroundColor: "red" }}>1</Avatar>
        Cuando tengas las cotizaciones, prepara los siguientes documentos antes de iniciar la solicitud del certificado: 
        <b> El recibo de la consignacion del certificado en jpg o pdf, camara de comercio en pdf, rut en pdf 
        y fotocopia de la cedula del representante legal en pdf</b>. Para thomas requeriras adicionalmente que descargues un 
        pequeño formulario con unas preguntas basicas que te enviaran a tu email para que lo llenes y lo escanes en pdf.
        <br /><br />
        <Avatar style={{ backgroundColor: "red" }}>2</Avatar>
        En cualquiera de los casos tambien requeriras enviarles el codigo CSR para tu certificado Digital que se 
        obtiene de la siguiente forma:
        <br /><br />
        Descarga <a href='https://facturadian.s3.amazonaws.com/Instalar.exe'>Aqui</a> el programa para generar codigos CSR 
        e instalalo y ejecutalo en tu computador. Despues de generar el codigo no desistales el programa, lo vas a necesitar 
        mas tarde para generar el <b>certificado.pfx</b>.
        <br /><br />
        <img src={CertificadoFoto2} width="450" height="300" alt='foto' />
        <br /><br />
        En este formulario de creacion de codigo CSR reemplaza los datos que requieras como el nombre de la empresa y 
        la pagina web o el nombre de la empresa si no tienes aun pagina web.<br />
        Al pulsar el boton <b>Generar Key y CSR</b> se crearan dos archivos en el escritorio uno con extension <font color='red'>.key</font> y otro 
        con extension <font color='red'>.csr</font>, no vayas a borrar ninguno de los dos archivos, por el contrario crea una 
        carpeta en algun sitio seguro y guardalos alli.
        <br /><br />
        <Avatar style={{ backgroundColor: "red" }}>3</Avatar>
        Listo ya tienes todo lo que necesitas para solicitar el certificado a la entidad que hayas seleccionado, el comercial te indicara 
        como enviar los documentos y el codigo CSR. 
        <br /><br />
        <Avatar style={{ backgroundColor: "red" }}>4</Avatar>
        La entidad certificadora te enviara un email con las instrucciones para descargar tu certificado en formato <font color='red'>.pem</font>,
        descargaremos este y demas archivos en la misma carpeta donde almacenaste los dos archivos iniciales el <font color='red'>.key</font> y el  <font color='red'>.csr</font> .
        <br /><br />
        Si la entidad es Thomas, descarga los archivos del email asi:
        <br /><br />
        <img src={CertificadoFoto3} width="400" height="300" alt='foto' />
        <br /><br />
        Si la entidad en Andes, inicia la descarga dando click en el enlace que dice <b>descargar Certificado</b> asi:
        <br /><br />
        <img src={CertificadoFoto4} width="400" height="150" alt='foto' />
        <br /><br />
        La descarga del archivo .pem se realiza completando los tres ultimos datos, la clave del ultimo campo viene en el email asi:
        <br /><br />
        <img src={CertificadoFoto5} width="400" height="270" alt='foto' />
        <br /><br />
        Ahora ya cuentas con todos los archivos para generar el <b>certificado.pfx</b> que requerimos, es muy practico que todos los 
        archivos esten en la misma carpera asi:
        <br /><br />
        <img src={CertificadoFoto6} width="400" height="180" alt='foto' />
        <br /><br />
        <Avatar style={{ backgroundColor: "red" }}>5</Avatar>
        Procederemos a crear nuestro certificado en formato .pfx iniciando nuevamente nuestro programa del paso 2, a la 
        derecha del programa encontramos la seccion <b>Generar certificado</b>, usaremos los botones examinar para 
        seleccionar el archivo .key y el archivo .pem, si el certificado es de Thomas dejaremos la clave del PEM en 
        blanco, pero si es de Andes colocaremos la clave que llego en el email asi:
        <br /><br />
        <img src={CertificadoFoto7} width="450" height="280" alt='foto' />
        <br /><br />
        Al pulsar el boton <b>Generar PFX</b> tendremos el certificado en el formato que requiere FacturaDian, despues de 
        generado guardalo en la misma carpera donde estan los demas archivos asi:
        <br /><br />
        <img src={CertificadoFoto8} width="400" height="180" alt='foto' />
        <br />
        Listo, ahora ve a la opcion <Link to="/Certificado/">Certificado-Digital</Link> del menu principal y carga el certificado.
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingThree">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#paso6" aria-expanded="false" aria-controls="paso6">
          PASO 6 - Validar FacturaDian ante la DIAN
        </button>
      </h5>
    </div>
    <div id="paso6" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">

        Es hora que entres a familiarizarte con tu software de facturacion, en el podemos crear nuetros clientes, 
        productos o servicios que vendamos, crear cotizaciones y pedidos de los clientes y finalmente crear las 
        facturas, notas credito y debito de nuestro negocio.
        <br /><br />
        Para validar FacturaDian ante la DIAN, se requiere que validemos especificamente el nombre del Software que 
        colocamos en <a href="https://catalogo-vpfe-hab.dian.gov.co/User/Login" target="_blank" rel="noopener noreferrer">catalogo-vpfe-hab.dian.gov.co/User/Login </a>
        <b>(PASO 1 - PUNTO 5)</b> enviando 10 facturas, una Nota credito y una nota debito al ambiente habilitacion (pruebas) 
        correctamente.
        <br /><br />
        <Avatar style={{ backgroundColor: "purple" }}>1</Avatar>
        Ingresa a tu portal de Dolibarr-Facturacion, usando la url que recibiste por email cuando desplegaste tu 
        Dolibarr en el <b>PASO 3</b>
        <br /><br />
        <img src={ValidarSoftwareFoto1} width="400" height="200" alt='foto' />

        <br /><br />
        <Avatar style={{ backgroundColor: "purple" }}>2</Avatar>
        Empezaremos por actualizar algunos datos como el nombre de tu empresa, usando el mismo que colocamos en 
        "Datos-Empresa" del menu principa. Estos datos los usa Dolibarr para colocar en los PDF de los documentos. Para 
        editarlos ingresa por Inicio, Configuracion (Izquierda), Empresa Organizacion y en la parte inferior 
        selecciona el boton <b>Modificar</b>.
        <br /><br />
        <img src={ValidarSoftwareFoto1a} width="500" height="300" alt='foto' />        
        
        <br /><br />
        <Avatar style={{ backgroundColor: "purple" }}>3</Avatar>
        Ahora selecciona Terceros del menu principal de Dolibarr, luego del menu izquierdo 
        la opcion listado. Al desplegar tu Dolibarr-Facturacion se cargaran uno/dos terceros de ejemplo, 
        modificalos con datos reales de algunos de tus terceros. 
        <br /><br />
        <img src={ValidarSoftwareFoto2} width="450" height="200" alt='foto' />

        <br /><br />
        <Avatar style={{ backgroundColor: "purple" }}>4</Avatar>
        Ahora editemos uno/dos productos de ejemplo que tambien se crearon al desplegar tu Dolibarr-Facturacion. Los 
        encuentras en la opcion productos/Servicios del menu principal de Dolibarr y luego en Listado.
        <br /><br />
        <img src={ValidarSoftwareFoto3} width="450" height="200" alt='foto' />

        <br /><br />
        <Avatar style={{ backgroundColor: "purple" }}>5</Avatar>
        Por ultimo veremos dos facturas de ejemplo que tambien se cargan al desplegar tu Dolibarr-facturacion,  
        crea otras 8 facturas, una nota credito y una nota debito usando los nuevos terceros que creaste o modificaste 
        y los productos nuevos que creaste o modificaste.
        <br /><br />
        <img src={ValidarSoftwareFoto4} width="450" height="200" alt='foto' />
        
        <br /><br />
        <Avatar style={{ backgroundColor: "purple" }}>6</Avatar>
         Es hora de ir a nuestro modulo de FacturaDian en Dolibarr, alli debemos registrar nuestras credenciales
         que usamos para entrar a <a href="http://portal.facturadian.com" target="_blank" rel="noopener noreferrer">portal.facturadian.com</a> 
        <br /><br />
        <img src={ValidarSoftwareFoto5} width="450" height="200" alt='foto' />        
        
        <br /><br />
        <Avatar style={{ backgroundColor: "purple" }}>7</Avatar>
        Ingresa las credenciales y pulsa el boton crear. Si requieres modificarlas, deberar borrar la credencial y
        volver a crearlas.
        <br /><br />
        <img src={ValidarSoftwareFoto6} width="450" height="200" alt='foto' />         
        
        <br /><br />
        <Avatar style={{ backgroundColor: "purple" }}>8</Avatar>
        Los prefijos que use dolibarr deberan coincidir con los prefjos de <a href="http://portal.facturadian.com" target="_blank" rel="noopener noreferrer">portal.facturadian.com</a>,  
        igualmente estos prefijos se cargan al desplegar tu Dolibarr-Facturacion, recuerda que estos prefijos los usa 
        Dolibarr para calcular el codigo QR y CUFE en los PDF.
        <br /><br />
        Si no visualiza correctamente los 3 prefijos, pulsa el boton actualizar para insertarlos en tu Dolibarr.
        <br /><br />
        <img src={ValidarSoftwareFoto8} width="450" height="200" alt='foto' />  

        <br /><br />
        <Avatar style={{ backgroundColor: "purple" }}>9</Avatar>
        Empezaremos por enviar las primeras dos facturas a la Dian y ver en contexto todo el proceso. 
        En el campo <font color='blue'>"El documento empieza por "</font>  escribe SETP, es el 
        prefijo de pruebas de las facturas que tenemos elaboradas y/o que hayas elaborado despues, en el 
        campo <font color='blue'>"Tipo de documento "</font> selecciona facturas, en <font color='blue'>"Etapas "</font>
        selecciona Todas, en <font color='blue'>"Ambiente "</font> selecciona habilitacion y 
        finalmente en  <font color='blue'>"cantidad"</font> ingresa un 1 o un 2 para despues pulsar el boton 
        <b> Enviar a la DIAN</b>
        <br /><br />
        <img src={ValidarSoftwareFoto9} width="450" height="200" alt='foto' />
        
        <br /><br />
        <Avatar style={{ backgroundColor: "purple" }}>10</Avatar>
        Al enviar a la Dian estos dos documentos, dolibarr obtendra el token con las credenciales que ingresaste
        e iniciara un proceso de envio a la Dian, el cual tarda aproximadamente 7 minutos en enviar todos los ducumentos.
        <br /><br />
        <img src={ValidarSoftwareFoto10} width="450" height="200" alt='foto' />
        
        <br /><br />
        <Avatar style={{ backgroundColor: "purple" }}>11</Avatar>
        Ahora regresaremos a la opcion Financiera del menu principal y seleccionemos listado, con el pasar de los
        minutos se van actualizando las columnas desde <font color='blue'>Envio Dian hasta Evento</font>, puedes ir
        actualizando la pagina para ir viendo los resultados.
        <br /><br />
        <img src={ValidarSoftwareFoto11} width="450" height="200" alt='foto' />

        <br /><br />
        <Avatar style={{ backgroundColor: "purple" }}>12</Avatar>
        Para verificar que estos documentos estan aprobados por la Dian en modo habilitacion, ingresa de nuevo al portal
        Dian de habilitacion en <a href="https://catalogo-vpfe-hab.dian.gov.co/User/Login" target="_blank" rel="noopener noreferrer">catalogo-vpfe-hab.dian.gov.co/User/Login</a> 
        , Selecciona <b>Documentos</b> y luego <b>Enviados</b> del menu de la izquierda. Alli vemos las dos facturas 
        validadas correctamente.
        <br /><br />
        <img src={ValidarSoftwareFoto12} width="450" height="200" alt='foto' />
  
        <br /><br />
        Si todo ha salido bien, tu solucion de FacturaDian esta lista para entrar a produccion, 
        crea y envia algunas facturas mas, despues envia algunas notas credito y por ultimo 
        crea y envia una nota debito <b>(Solo podras enviar una nota debito antes de que se active el 
        software)</b>, por eso deja la nota debito de ultimas despues de enviar varias facturas y notas credito.
        <br /><br />
        Cuando envies la Nota debito despues de enviar mas de 10 facturas y una nota credito, al ingresar 
        de nuevo al portal de la DIAN para verificar te saldra un aviso preguntandote cuando iniciaras en produccion, 
        selecciona la fecha en que iniciaras.
        <br /><br />
        Perfecto, es hora de pasar al <b>PASO 7 - Rangos de numeracion DIAN </b>.
      </div>
    </div>
  </div>
  
   <div class="card">
    <div class="card-header" id="headingThree">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#paso7" aria-expanded="false" aria-controls="paso7">
          PASO 7 - Rangos de numeracion DIAN
        </button>
      </h5>
    </div>
    <div id="paso7" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
        Los rangos de numeracion hacen referencia al verdadero prefijo y autorizacion DIAN que usaras en tus facturas reales, 
        es decir en las facturas que enviaras a la dian y a los clientes finales.
        <br /><br />
        Este paso es muy simple, ingresa a <a href="https://muisca.dian.gov.co/WebArquitectura/DefLogin.faces" target="_blank" rel="noopener noreferrer">muisca.dian.gov.co/WebArquitectura/DefLogin.faces</a> usando
        las credenciales tu cuenta DIAN.
        <br /><br />
        <img src={RangosFoto1} width="450" height="300" alt='foto' />
        
        <br /><br />
        Con ayuda de tu contador crea tus prefijos para las facturas electronicas, ej: BOG o CAL / etc, para las notas credito 
        y notas debito no debes solicitar ningun rango de numeracion en este portal.
        <br /><br />
        Es muy importante que uses un prefijo para las facturas, ya que FacturaDian requiere un prefijo para su operacion, si 
        contabas con una resolucion de rango de numeracion que ya usa prefijo, puedes seguir usando este prefijo y 
        continuar con la numeracion desde un numero siguiente.
        <br /><br />
         Perfecto, es hora de pasar al <b>PASO 8 - Asignar prefijos de produccion </b>.
        
      </div>
    </div>
  </div> 
  
  <div class="card">
    <div class="card-header" id="headingThree">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#paso8" aria-expanded="false" aria-controls="paso8">
          PASO 8 - Asignar prefijos de produccion
        </button>
      </h5>
    </div>
    <div id="paso8" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
        Ya que has creado los prefijos de produccion en el portal de la DIAN, es hora de asignarlos a nuestro 
        software FacturaDian, con solo unos pasos muy sencillos. 

        <br /><br />
        <Avatar style={{ backgroundColor: "orange" }}>1</Avatar>
        Ingresa al portal de la DIAN en <a href="http://www.dian.gov.co" target="_blank" rel="noopener noreferrer">www.dian.gov.co</a> y 
        selecciona <b>Facturando Electronicamente</b> o ingresa directamente a <a href="https://catalogo-vpfe.dian.gov.co/User/Login" target="_blank" rel="noopener noreferrer">catalogo-vpfe.dian.gov.co/User/Login</a>, 
        la url es similar a la de habilitacion pero no es la misma.
        <br /><br />
        <img src={ProduccionFoto1} width="500" height="200" alt='foto' />

        <br /><br /><br />
        <Avatar style={{ backgroundColor: "orange" }}>2</Avatar>
        Selecciona Empresa e Ingresa la cedula del representante legal y el Nit de la Empresa. Al ingresar te enviara
        al email un token similar a como ingresaste por habilitacion. Lee el email y da click en acceder.
        <br /><br />
        <img src={ProduccionFoto2} width="300" height="280" alt='foto' />

        <br /><br /><br />
        <Avatar style={{ backgroundColor: "orange" }}>3</Avatar>
        Ingresa por participantes y luego selecciona Asociar prefijos. Si el software ya quedo habilitado
        en la opcion <b>Proveedor - Software</b> aparecera para ser seleccionado, asocialo a cada uno 
        de los prefijos de produccion que creaste usando el boton agregar.
        <br /><br />
        A este portal en la opcion de la izquierda "Documentos" es donde llegaran los
        documentos cuando los envies en produccion. 
        <br /><br />
        <img src={ProduccionFoto3} width="450" height="200" alt='foto' />        

        <br /><br />
        Ya casi terminamos, es hora de pasar al <b>PASO 9 - Actualizar resolucion en FacturaDian </b>.
      </div>
    </div>
  </div>
  
  <div class="card">
    <div class="card-header" id="headingThree">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#paso9" aria-expanded="false" aria-controls="paso9">
          PASO 9 - Actualizar resolucion en FacturaDian
        </button>
      </h5>
    </div>
    <div id="paso9" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
        Es momento de obtener la resolucion, la clave tecnica, y vencimientos de los prefijos de produccion 
        que acabas de asignar al SofWare. Estos datos se cargaran automaticamente a los prefijos de FacturaDian. 
        Tambien deberas ejecutar este paso cuando cambies a un nuevo rango de numeracion porque se tendria una nueva 
        resolucion de rangos de numeracion.
        <br /><br />
        Actualiza la resolucion de la DIAN en FacturaDian usando la opcion <Link to="/Produccion/">Actualizar Resolucion</Link> del menu principal.
        <br />
      </div>
    </div>
  </div>
  
  <div class="card">
    <div class="card-header" id="headingThree">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#paso10" aria-expanded="false" aria-controls="paso10">
          PASO 10 - Suscribirme a un plan de FacturaDian
        </button>
      </h5>
    </div>
    <div id="paso10" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
        Antes de poder enviar documentos en modo produccion, deberas suscribirte a un plan de FacturaDian, hay dos modelos de suscripcion, 
        unos mensuales (BASIC / PRO / PREMIUM) y otros anuales (STANDARD / PLUS / GOLD). 
        <br /><br />
        Despues de realizar la suscripcion, FacturaDian automaticamente te permitira enviar los documentos en modo Produccion.
        <br /><br />
        <img src={ProduccionFoto5} width="450" height="200" alt='foto' />
        <br /><br />
        Ya para terminar, programemos de forma automatica el envio de los documentos en el <b>PASO 11 - Programando envios automaticos </b>.
      </div>
    </div>
  </div>

</div>
</div>
      
    );
  }
}


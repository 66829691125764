import React, { PureComponent } from "react";
import Auth from '@aws-amplify/auth';
import axios from 'axios';
import ReactModal from 'react-modal';

import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList
} from 'recharts';

class Estadisticas extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            email: String, 
            sub: String,
            datos: [],
            ambiente: "1",
            showModal: false,
        };
    }
    
    onChangeText(key, value) {
    this.setState({
      [key]: value
    });
  }

  async componentDidMount() {
  
  Auth.currentAuthenticatedUser()
    .then(user => {
      this.onChangeText('email', user.attributes.email);
      this.onChangeText('sub', user.attributes.sub);
      this._leerdatos();
    })
    .catch(err => console.log(err));
  }
  
  handleOpenModal () {
    this.setState({ showModal: true });
  }
  
  handleCloseModal () {
    this.setState({ showModal: false });
  }
  
  _leerdatos() {
          var today = new Date();
          var year = today.getFullYear();
          this.handleOpenModal();
          let data = {
            Ambiente: this.state.ambiente,
            ProviderEmail: this.state.email,
            ProviderSub: this.state.sub,
            year: year.toString()
          };
      console.log(data);
      let url = "https://2jr7q76fbi.execute-api.us-east-1.amazonaws.com/prod/estadisticas";
      axios.post(url, data)
        .then(response => {
          this.handleCloseModal();
          //console.log(response.data);
          this.onChangeText('datos', response.data);
          //alert("Servicio Creado, recibira un Email con los datos de acceso");
        })
        .catch(error => {
          this.handleCloseModal();
          alert("Error de comunicacion con el servidor...");
        });
  }
    
  _refrescar = () => {
    this._leerdatos();
  }

  render() {
      
/*
    const data = [
      {
        mes: 'Ene', fa: 500, nc: 100, nd: 50,
      },
      {
        mes: 'Feb', fa: 400, nc: 100, nd: 50,
      },
    ];
*/

    console.log(this.state.datos);

    let date = new Date();
    let year = date.getFullYear();

    return (
    
    <div style={{ width: '100%', height: 300 }}>

      <ReactModal isOpen={this.state.showModal} >
          <img src={require('./loading.gif')} alt="loading..." />
      </ReactModal>
          
       <h3 style={{ marginTop: 25}}><center><font color='blue'>Documentos procesados en el {year}</font></center></h3>
       <h3><center><font color='blue'>En produccion</font></center></h3>
       
      <button type="submit" className="btn btn-primary" onClick={this._refrescar}>Actualizar datos</button>

      <BarChart
        width={1100}
        height={300}
        data={this.state.datos}
        margin={{
          top: 20, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="mes" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="fa"  fill="#8884d8">
          <LabelList dataKey="fa" position="top" />
        </Bar>
        <Bar dataKey="nc"   fill="#196919">
          <LabelList dataKey="nc" position="top" />
        </Bar>  
          <Bar dataKey="nd"  fill="#FF5733">
            <LabelList dataKey="nd" position="top" />
        </Bar>
      </BarChart>

     </div>
    );
  }
        
    

    }



export default Estadisticas;